import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { FloatingMessage, useFloatingMessage } from '../UserAction/FloatingMessage';

const Map = ({ position, isVisible, hide, showMarker, id }) => {

    const { t } = useTranslation();
    const floatMsg = useFloatingMessage();
    const [newPosition, setNewPosition] = useState({});

    const handleMarkerDragEnd = async (event) => {
        const newPos = { lat: event.latLng.lat(), lng: event.latLng.lng() };

        setNewPosition(newPos);
    };

    const showMessage = (message, isError) => {
        floatMsg.show(message, isError);
        // Set a timer to hide the message after 10 seconds
        const timer = setTimeout(() => {
            floatMsg.hide();
        }, 10000);

        // Clear the timer when the component unmounts or if the message changes
        return () => clearTimeout(timer);
    };

    const handleUpdate = async () => {
        if (id) {
            const response = await axios.post("/api/updatePosition", {newPosition, id});

            if (response.status === 200) {
                hide(newPosition);
            } else {
                showMessage(t('position-update-failed'), true);
            }
        } else {
            showMessage(t('position-update-failed'), true);
        }
    };

    if (!isVisible) return null;

    return (
        <div className="userDialogContainer">
            <div className="confirmDialog">
                <FloatingMessage {...floatMsg} />
                <div className='mapContainer'>
                    <div className='mapHeader'>{t('map-location')}</div>
                    <div className='mapSubHeader'>{t('google-maps')}</div>
                    <div className='map'>
                        <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                            {<GoogleMap
                                center={position}
                                zoom={17}
                                mapContainerStyle={{ width: '300px', height: '200px' }}
                                options={{
                                    mapTypeId: 'satellite',
                                    zoomControl: true,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                    contolSize: 5,
                                    keyboardShortcuts: false
                                }}
                            >
                                {showMarker &&
                                    <Marker
                                        position={position}
                                        draggable={true}
                                        onDragEnd={handleMarkerDragEnd}
                                    />
                                }
                            </GoogleMap>}
                        </LoadScriptNext>
                    </div>
                    <div className='mapBtnGroup'>
                        {showMarker && <button className='update-position' onClick={handleUpdate}>{t('update-position')}</button>}
                        <button onClick={() => hide()} className='cancel-button'>{t('close-button')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Map;
