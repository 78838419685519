import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useLocation, useNavigate } from "react-router-dom"
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');
const ProductList = ({ elements }) => {
    const loading = useLoading();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [hideZeroQuantity, setHideZeroQuantity] = useState(false);
    const [redirectTo, setRedirectTo] = useState('/installation');
    const location = useLocation();
    const [installationId, setInstallationId] = useState('');

    const getData = async () => {
        try {
            const installationId = (await axios.get('/api/getCurrentInstallationId')).data;

            const response = await axios.get('/api/fetchProductList');
            console.log('Product list:', response.data);

            const existingBomLines = await axios.get(`/api/bom/byInstallationId/${installationId}`);
            console.log('Existing product list:', existingBomLines.data);

            const products = response.data;

            products.forEach(product => {
                const match = existingBomLines.data.find(bomLine => bomLine.id === product.RecId);
                return product.quantity = match ? match.quantity : 0
            });

            // Update the state with the fetched product list
            setInstallationId(installationId);
            setProducts(products);
        } catch (error) {
            console.error(t('set-product-error-message'), error);
        }
        loading.hideLoading();

    };

    const getRedirectUrl = async () => {
        const redirectURL = location.state.redirectTo;
        console.log("Got the following redirect url", redirectURL);

        if (redirectURL) {
            setRedirectTo(redirectURL);
        }
    };

    const generatePDF = async () => {
        loading.showLoading(t('loading-generating-bom'));
        const nonZeroProducts = products.filter(p => p.quantity > 0);
        const zeroProducts = products.filter(p => p.quantity === 0);

        try {

            const bomLines = nonZeroProducts.map(product => {
                return {
                    Id: product.RecId,
                    Quantity: product.quantity,
                    ProductId: product.ItemId,
                    ProductName: product.ProductVariants[0].AttributeValues.filter(a => a.Name === "Varenavn")[0].Value,
                    installationId: installationId,
                    ProductImage: product.ProductVariants[0].Images[0]
                }
            });

            const deleteBomLines = zeroProducts.map(product => {
                return {
                    Id: product.RecId,
                    Quantity: product.quantity,
                    ProductId: product.ItemId,
                    ProductName: product.ProductVariants[0].AttributeValues.filter(a => a.Name === "Varenavn")[0].Value,
                    installationId: installationId,
                    ProductImage: product.ProductVariants[0].Images[0]
                }
            });

            const response = await axios.post(`/api/bom/createMultiple/${installationId}`, { bomLines });
            const deleteResponse = await axios.delete('/api/bom/deleteMultiple', { data: deleteBomLines });

            if (response.status === 201) {
                loading.hideLoading();
                navigate(`${redirectTo}`);
            }
        } catch (error) {
            console.error('Error creating multiple BomLines:', error);
            loading.hideLoading();
        }


        /*
        if (nonZeroProducts.length === 0) {
            alert(t('no-product-selected-alert'));
            loading.hideLoading();
            return;
        }

        setHideZeroQuantity(true);

        const pdfWidth = 210;
        const pdfHeight = 297;

        await new Promise(resolve => setTimeout(resolve));
        const table = document.getElementById("product-table").cloneNode(true);
        const imgs = table.getElementsByTagName("img");
        Array.from(imgs).forEach(img => {
            img.parentElement.removeChild(img);
        });
        table.classList.add("for-pdf");
        document.body.appendChild(table);
        const canvas = document.createElement('canvas');
        canvas.width = pdfWidth * 6;
        canvas.height = pdfHeight * 6;
        await html2canvas(table, { canvas: canvas, scale: 2 });

        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

        const blob = pdf.output("blob");
        console.log(blob);
        const formData = new FormData();

        formData.append('file', blob, t('bom') + ".pdf");
        formData.append("isEditable", false);
        formData.append("type", 3);
        axios.post('/api/uploadFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            (async () => {
                navigate(`${redirectTo}`)
            })();
        });

        document.body.removeChild(table);
        //pdf.save("products.pdf");
        setHideZeroQuantity(false);*/
    };

    useEffect(() => {
        loading.showLoading(t('loading-fetching-products'));
        getRedirectUrl();
        getData();
    }, []);  // Empty dependency array means this useEffect runs once, similar to componentDidMount

    const { t } = useTranslation();

    return (
        <div className='productListContainer componentContainer'>
            <div className='approveInstallationInstallationIdContainer'>
                <div className='approveInstallationInstallationId'>ID.: {installationId}</div>
            </div>
            <Loading isLoading={loading.isLoading} message={loading.message} />
            <div className='productListHeaderContainer'>
                <p className='productListHeader'>{t('product-list-header')}</p>
                <div className='productListInfo'>{t('product-list-info')}</div>
            </div>
            <div className='product-list-table-header'>
                <div className='product-list-table-header-empty' />
                <div className='product-list-table-header-id'>{t('product-list-item-id')}</div>
                <div className='product-list-table-header-name'>{t('product-list-item-name')}</div>
                <div className='product-list-table-header-qty'>{t('product-list-item-qty')}</div>
            </div>
            <div className='product-list-table-container'>
                <div className='productList' id="product-table">

                    {products && (hideZeroQuantity ? products.filter(p => p.quantity > 0) : products).map((product, index) => (
                        // Assuming each product has a unique id
                        <ProductListElement key={product.ItemId} element={product} />
                    ))}
                </div>
            </div>
            <div className="createPDFBtnContainer">
                <button className='back-button' onClick={() => navigate(`${redirectTo}`)}>{t('back-button')}</button>
                <button className='createPDFBtn' onClick={generatePDF}>{t('create-bom-button')}</button>
            </div>
        </div>
    );
};

const ProductListElement = ({ element }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [quantity, setQuantity] = useState(0);

    const handleError = (e) => {
        e.target.onerror = null;  // Prevent infinite loop in case the fallback image also fails to load
        e.target.src = 'https://images-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/imageFileData/search?fileName=/Products%2F113628_000_001.png&fallback=/Products/113628_000_001.png,placeholder.png&w=767&h=767&q=90&m=6&f=png&cropfocalregion=true';  // Replace with the URL of your fallback image
    }

    const toggleModal = () => {
        setModalVisible(prevVisible => !prevVisible);
    };

    const handleQuantityChange = (e) => {
        element.quantity = parseInt(e.target.value);
        console.log("setting quantity");
        setQuantity(parseInt(e.target.value));
    };

    const handleOk = () => {
        console.log(`Quantity selected: ${quantity}`);
        toggleModal();
    };

    console.log("printing...", element.quantity);

    const { t } = useTranslation();

    useEffect(() => {
        setQuantity(element.quantity);
    }, []);

    return (
        <div>
            <div className={parseInt(element.quantity) !== 0 ? "productListRow activeProduct" : "productListRow"} onClick={toggleModal}>
                <img className='product-list-table-image' src={element.ProductVariants[0].Images[0]} alt="Element" onError={handleError} />
                <div className='product-list-table-id'>{element.ItemId}</div>
                <div className='product-list-table-name'>{element.ProductVariants[0].AttributeValues.filter(a => a.Name === "Varenavn")[0].Value}</div>
                <div className='product-list-table-qty'>{element.quantity}</div>
            </div>
            <Modal className="quantityModal"
                isOpen={modalVisible}
                onRequestClose={toggleModal}
                contentLabel="Quantity Modal"
                overlayClassName="quantityModalOverlay"
            >
                <div className="quantityInputContainer">
                    <div className='quantityInputProductData'>
                        <img src={element.ProductVariants[0].Images[0]} alt="Element" onError={handleError} />
                        <div className='quantityInputProductInfo'>
                            <div className='quantityInputProductInfoId'>{element.ProductVariants[0].AttributeValues.filter(a => a.Name === "Varenummer")[0].Value}</div>
                            <div className='quantityInputProductInfoName'>{element.ProductVariants[0].AttributeValues.filter(a => a.Name === "Varenavn")[0].Value}</div>
                        </div>
                    </div>
                    <div className='quantityInputQtyContainer'>
                        {t('label-quantity')}
                        <input className='quantityInputQty' type="number" defaultValue={element.quantity} value={quantity} onChange={handleQuantityChange} min="0" autoFocus onFocus={e => e.target.select()} />
                    </div>
                    <button onClick={handleOk}>{t('ok-button')}</button>
                </div>
            </Modal>
        </div>
    );
};

export default ProductList;

