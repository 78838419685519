import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Login from './Components/Login/LoginFormSimplified';
import Navbar from './Components/Navbar/Navbar';
import Installation from './Components/Installation/Installation';
import Register from './Components/User/RegisterSimplified';
import ProductList from './Components/BOMCreator/BomCreator';
import Installations from './Components/Installations/Installations';
import InstallationIDDispatcher from './Components/Dispatcher/InstallationIDDispatcher';
import Footer from './Components/Footer/Footer';
import VerifyUserToken from './Components/User/Verify';
import ForgotPassword from './Components/User/ForgotPassword';
import ChangePassword from './Components/User/ChangePassword';
import InstallationInput from './Components/Installation/InstallationInput';
import UnregisteredInstallation from './Components/LandingSite/UnregisteredInstallation';
import InstallationApproval from './Components/Installation/InstallationApproval';
import Projects from './Components/Projects/Projects';
import ProfileForm from './Components/User/ProfileForm';
import EditUserForm from './Components/User/EditUserForm';
import UserRoles from './Components/User/UserRoles';
import { Inspections } from './Components/Inspections/Inspections';
import Contact from './Components/Information/Contact';
import FAQ from './Components/Information/FAQ';
import TrackPageView from './Utils/GTM';

function App() {
    return (
        <Router>
            <TrackPageView />
            <Navbar />
            <Routes>
                <Route path="/createBOM" element={<ProductList />} />
                <Route path="/login" element={<Login redirectTo="/installations" />} />
                <Route path="/installation/:id" element={<Installation />} />
                <Route path="/Register" element={<Register />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/installations" element={<Installations />} />
                <Route path="/createInstallation" element={<InstallationInput />} />
                <Route path="/approveInstallation" element={<InstallationApproval />} />
                <Route path="/" element={<InstallationIDDispatcher redirectTo="/installations" />} />
                <Route path="/unregisteredInstallation" element={<UnregisteredInstallation redirectTo="/login" />} />
                <Route path="*" element={<Login redirectTo="/installations" />} />
                <Route path="/verify/" element={<VerifyUserToken />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/changePassword" element={<ChangePassword />} />
                <Route path="/profile" element={<ProfileForm />} />
                <Route path="/editProfile" element={<EditUserForm />} />
                <Route path="/changeUserRoles" element={<UserRoles />} />
                <Route path="/previousInspections" element={<Inspections />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/faq" element={<FAQ />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
