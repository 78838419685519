import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import logo from '../../LOBAS-LOGO.svg';
import f from './CredentialsFunctions'
import { set } from "react-hook-form";

const ChangePassword = () => {
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const [verifyingToken, setVerifyingToken] = useState(true);
    const loading = useLoading();
    const location = useLocation();
    const { t } = useTranslation();
    const [passwordChanged, setPasswordChanged] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState(null);

    const token = queryParams.get('token');

    useEffect(() => {
        (async () => {
            loading.showLoading(t('verifying-token'));
            if (token) {
                // Send the token to backend for verification
                const response = await axios.post('/api/verifyChangePasswordToken', { token: token })
                setVerifyingToken(false);
                if (response.status !== 200) {
                    loading.showLoading(t('token-verification-failed'));
                    setTimeout(() => navigate("/Login"), 3000);
                }
            } else {
                loading.showLoading(t('token-verification-failed'));
                setTimeout(() => navigate("/Login"), 3000);
            }

            loading.hideLoading();

        })();
    }, [])


    const onSubmit = async (e) => {
        e.preventDefault();
        loading.showLoading(t('changing-password'));

        const password = passwordRef.current.value;
        const confirmPassword = confirmPasswordRef.current.value;

        if (password !== confirmPassword) {
            setErrorMessage(t('passwords-dont-match'));
        } else if (!f.isValidPassword(password)) {
            setErrorMessage(t('invalid-password'));
        }
        else {
            try {
                const response = await axios.post('/api/resetPassword', { newPassword: password, token });
                if (response.status === 200) {
                    setPasswordChanged(true);

                    setTimeout(function () {
                        navigate("/login");
                    }, 2000);
                }
                else {
                    setErrorMessage(t('error-changing-password'));
                }
            } catch (error) {
                console.error("Error:", error);
                setErrorMessage(t('error-changing-password'));
            }
        }

        loading.hideLoading();
    };

    return (
        <div className="loginFormContainer">
            <Loading isLoading={loading.isLoading} message={loading.message} />
            <form onSubmit={onSubmit} className="loginForm">
                <div className="logoContainerLogin">
                    <img src={logo} alt="LOBAS" title="LOBAS" className="logosvg" />
                </div>
                {!passwordChanged && <div className="requestPasswordContainer">
                    <div className="input-default">
                        <div className="div">{t('enter-password')}</div>
                        <input
                            className="input-style"
                            type="password"
                            placeholder={t('enter-password')}
                            ref={passwordRef}
                        />
                    </div>
                    <div className="input-default">
                        <div className="div">{t('confirm-password')}</div>
                        <input className="input-style"
                            type="password"
                            placeholder={t('confirm-password')}
                            ref={confirmPasswordRef}
                        />
                    </div>
                    <div className="register-button">
                        <button className="submit-button" type="submit" disabled={loading.isLoading}>{t('request-password-change')}</button>
                    </div>
                    {errorMessage && <p>{errorMessage}</p>}
                </div>}
                {passwordChanged && <p className="checkEmailMessage">{t("password-changed")}</p>}
            </form>
            <span className="registerLink navigateSpan" onClick={() => navigate(`/Login`)}>{t('login-button')}</span>
        </div>
    )
}
export default ChangePassword
