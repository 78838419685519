import { NavLink } from "react-router-dom";
import { useUser } from "../../Context/UserContext";
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import { useEffect, useState } from "react";
import UserConfirm from '../UserAction/UserConfirm';
import useConfirmationDialog from '../UserAction/useConfirmationDialog';
import logo from '../../LOBAS-LOGO.svg';
import LanguagePicker from '../Language/LanguagePicker';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHamburger } from "@fortawesome/free-solid-svg-icons";
import Menu from "./Menu/Menu";

const Navbar = () => {
  const [selectedLanguage, setLanguage] = useState('');
  const confirmDialog = useConfirmationDialog();
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const [showMenu, setShowMenu ] = useState(false);

  const getUser = async () => {
    if (user !== null) return;

    const response = await axios.get('/api/getCurrentUser');
    console.log('User:', response.data);
    setUser(response.data);
  };

  useEffect(() => {
    const loginEventOccured = (e) => {
      console.log('Login event occurred');
      setUser(e.detail.user);
    };

    const titleChangeRequest = (e) => {

    };

    window.addEventListener('userLoggedIn', loginEventOccured);

    return () => {
      // Cleanup
      window.removeEventListener('userLoggedIn', loginEventOccured);
    };
  }, []);

  useEffect(() => {
    getUser();
  }, [user]);

  const handleLogout = async () => {

    confirmDialog.show(async () => {
      await axios.get('/api/logout');
      setUser(null);
      navigate('/login');
    }, t('prompt-logout'))
  };

  const handleLanguageChange = (languageLabel) => {
    let languageCode;
    if (languageLabel === 'Norsk') {
      languageCode = 'no';
    } else {
      languageCode = 'en';
    }
    setLanguage(languageLabel);
    i18n.changeLanguage(languageCode);
  };


  useEffect(() => {
    //handleLanguageChange("Norsk");
  }, []);


  const { t } = useTranslation();

  const showMenuClick = () => {
    setShowMenu(true);
  }

  return (
    <nav>
      <UserConfirm {...confirmDialog} />
      <div className="logoContainer" onClick={() => navigate("/projects")}>
          <img src={logo} alt="LOBAS" title="LOBAS" class="logosvg" />
      </div>
      <Menu isOpen={showMenu} onClosed={() => setShowMenu(false)} isGuest={!user}/>
      <div className="nav-buttons">
        {!user && <NavLink className="nav-link" to="/">{t('login-button')}</NavLink>}
        {/* {user && <NavLink className="nav-link" to="/installations">{t('installations-button')}</NavLink>} */}
        {/* {user && <div onClick={handleLogout} className="nav-link" to="/">{t('logout-button')}</div>} */}
        <FontAwesomeIcon icon={faBars} size="lg" className="nav-menu" onClick={showMenuClick} />
      </div>
    </nav>
  );
};

export default Navbar;
