import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import UserConfirm from '../UserAction/UserConfirm';
import useConfirmationDialog from '../UserAction/useConfirmationDialog';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import { NavLink } from 'react-router-dom';
import { useUserInputDialog, UserInputDialog } from '../UserAction/UserInputDialog';
import { useTranslation } from "react-i18next";
import QRScanner from '../QrReader';
import { useTokenNavigate } from '../NavigateHook';
import { useFloatingMessage, FloatingMessage } from '../UserAction/FloatingMessage';
import { useNavigate } from 'react-router-dom/dist';
import { GoogleMap, LoadScript, Marker, LoadScriptNext } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Projects = () => {
    const changeNameInput = useUserInputDialog();
    // const userInputDialog = useUserInputDialog();
    const confirmDialog = useConfirmationDialog();
    const loading = useLoading();
    const [installations, setInstallations] = useState(null);
    const [filteredInstallations, setFilteredInstallations] = useState(null);
    const [scanSticker, setScanSticker] = useState(false);
    const nav = useTokenNavigate();
    const floatingMessage = useFloatingMessage();
    const navigate = useNavigate();
    const [showSearch, setShowSearch] = useState(false);
    const searchString = useRef();


    const getInstallations = async () => {
        console.log("attempting to get installations");
        let installations = [];
        try {
            installations = (await axios.get('/api/getInstallationsFromUser')).data;
            console.log(installations);
        } catch (error) {
            nav.navigateTo('/login');
        }
        console.log(installations);

        if (installations.length > 0) {
            const installationsGrouped = installations.reduce((group, installation) => {
                const { address } = installation;
                group[address] = group[address] ?? [];
                group[address].push(installation);
                return group;
            }, {});


            setInstallations(installations);
            setFilteredInstallations(installationsGrouped);
        }
        loading.hideLoading();
    }

    useEffect(() => {
        loading.showLoading(t('fetching-message'));
        getInstallations();
    }, []);

    const addInstallationManually = async () => {
        setScanSticker(true);
    }

    const handleScanCancelled = () => {
        setScanSticker(false);
    }

    const handleScan = (scanContent) => {
        setScanSticker(false);
        loading.showLoading(t("verifying-qr-code"));
        console.log("scanned content", scanContent);
        let cutoff = scanContent.indexOf('?');
        scanContent = scanContent.substring(cutoff + 1);
        const searchParams = Object.fromEntries(new URLSearchParams(scanContent));
        console.log("search params", searchParams);
        const sn = searchParams.id;
        const tkn = searchParams.tkn;

        console.log(sn, tkn);

        if (!(sn && tkn)) {
            //TODO: Show a userInform which explains that the QR code is invalid
            console.log("invalid parameters detected");
            return;
        }
        else {
            (async () => {
                console.log("Checking if installation exists", sn);
                const doesExist = (await axios.post('/api/getInstallationFromId', { installationId: sn })).data != null;
                console.log("doesExist", doesExist);
                if (doesExist) {
                    nav.navigateTo(`/installation/${sn}`);
                } else {
                    const user = (await axios.get('/api/getCurrentUser')).data;
                    console.log("Got user")
                    if (user) {
                        // await axios.post('/api/createInstallation', { installationId: id, userId: user.id, key: key });
                        nav.navigateTo(`/createInstallation`, { sn: sn, tkn: tkn });
                    }
                    else {
                        nav.navigateTo('/login', { redirectTo: "/?sn=" + sn + "&tkn=" + tkn });
                    }
                }

                loading.hideLoading();

            })();
        }

    }

    const handleScanError = (error) => {
        floatingMessage.show(t("camera-error"), error);
        setScanSticker(false);
    }

    const handleError = (error) => {

    };

    const ProjectImage = ({ latitude, longitude }) => {
        const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // Replace with your API key
        const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=18&size=600x300&maptype=satellite&key=${apiKey}`;

        return <img src={mapUrl} alt="Bilde" className='project-image' />;
    }

    const handleSearch = () => {
        if (installations) {
            const newFilteredInstallations = installations.filter(installations => installations.address.includes(searchString.current.value) ||
                installations.installationId.includes(searchString.current.value));

            const installationsGrouped = newFilteredInstallations.reduce((group, installation) => {
                const { address } = installation;
                group[address] = group[address] ?? [];
                group[address].push(installation);
                return group;
            }, {});

            setFilteredInstallations(installationsGrouped);
        }
    }

    const { t } = useTranslation();

    return (
        <div className='projectsContainer'>
            {scanSticker && <QRScanner onScanSuccess={handleScan} onScanCancelled={handleScanCancelled} onError={handleScanError} />}
            <FloatingMessage {...floatingMessage} />
            <Loading {...loading} />
            <UserConfirm {...confirmDialog} />
            <div className='projectHeader'>
                <p>{t('projects-header')}</p>
                <div className='searchIconContainer' onClick={() => { setShowSearch((prevOpen) => !prevOpen) }}>
                    <FontAwesomeIcon className='searchIcon' icon={faSearch} size='lg' />
                </div>
            </div>
            <div className='projectsForm'>
                {showSearch &&
                    <div className='project-installation-search-container box-shadow'>
                        <div className="input-default">
                            <div className="div">{t('installation-search')}</div>
                            <input
                                className={"input-style"}
                                type="text"
                                placeholder="Skriv inn ID eller adresse"
                                ref={searchString}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                }
                {filteredInstallations && Object.entries(filteredInstallations).map(([address, unGroupedinstallations]) => (
                    <div className='projectRow'>
                        <ProjectImage
                            latitude={unGroupedinstallations[0].fullAddress.lat}
                            longitude={unGroupedinstallations[0].fullAddress.lng}
                        />
                        <div className='project-info-container'>
                            <div className='project-info'>
                                <div className="project-address">{address}</div>
                            </div>
                            <div className='project-bottom-row'>
                                <div className='project-link-container'>
                                    <button onClick={() => navigate('/installations', { state: { filters: { address, searchString: searchString.current && searchString.current.value } } })} className='installationsLink'>
                                        {t('show-installations')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {!filteredInstallations && <div>{t('no-installation-message')}</div>}

            </div>
            <div className='projectActionsContainer'>
                <button onClick={addInstallationManually}>{t('add-installation')}</button>
            </div>
        </div>
    );
};

export default Projects
