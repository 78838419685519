import LanguagePicker from '../../Language/LanguagePicker';
import { NavLink } from "react-router-dom";
import { useUser } from "../../../Context/UserContext";
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import { useEffect, useState } from "react";
import UserConfirm from '../../UserAction/UserConfirm';
import useConfirmationDialog from '../../UserAction/useConfirmationDialog';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHamburger, faX } from "@fortawesome/free-solid-svg-icons";

const Menu = ({ onClosed, isOpen, isGuest }) => {
    const [selectedLanguage, setLanguage] = useState('');
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    const confirmDialog = useConfirmationDialog();
    const { t } = useTranslation();

    const handleLogout = async () => {

        confirmDialog.show(async () => {
            await axios.get('/api/logout');
            setUser(null);
            closeMenu();
            navigate('/login');
        }, t('prompt-logout'))
    };

    const handleLanguageChange = async (languageLabel) => {
        const languageCode = languageLabel.toLowerCase();
        await i18n.changeLanguage(languageCode).then(() => {
            setLanguage(languageLabel);
        });
    };

    const closeMenu = () => {
        onClosed();
    }

    useEffect(() => {
        const language = i18n.language;
        setLanguage(language.toUpperCase())
    }, []);

    return (
        <div className={`menuBackground ${isOpen ? 'slide-in' : 'slide-out'}`} onClick={closeMenu}>
            <div className={`menuContainer ${isOpen ? 'slide-in' : 'slide-out'}`} onClick={(e) => e.stopPropagation()}>
                <UserConfirm {...confirmDialog} />
                <div className="menuHeaderContainer">
                    <div className="menuLanguagePicker">
                        <LanguagePicker options={['NO', 'EN']} value={selectedLanguage} onChange={handleLanguageChange}></LanguagePicker>
                    </div>
                    <div className="menuCloseBtnContainer">
                        <FontAwesomeIcon icon={faX} onClick={closeMenu} />
                    </div>
                </div>
                <div className="menuContentContainer">
                    {!isGuest && <div className="menuProjectsLink">
                        <div onClick={() => { closeMenu(); navigate("/projects") }}>{t('menu-projects-link')}</div>
                    </div>}
                    {!isGuest && <div className="menuInstallationsLink">
                        <div onClick={() => { closeMenu(); navigate("/installations", { state: { filters: {} } }) }}>{t('menu-installations-link')}</div>
                    </div>}
                    {!isGuest && <div className="menuProfileLink">
                        <NavLink to="/profile" onClick={() => closeMenu()}>{t('menu-profile-link')}</NavLink>
                    </div>}
                    <div className="menuFAQLink">
                        <NavLink to="/FAQ" onClick={() => closeMenu()}>{t('menu-faq-link')}</NavLink>
                    </div>
                    <div className="menuContactLink">
                        <NavLink to="/Contact" onClick={() => closeMenu()}>{t('menu-contact-link')}</NavLink>
                    </div>
                    {!isGuest && <div className="menuLogOutLink">
                        <button onClick={handleLogout}>{t('menu-logout-link')}</button>
                    </div>}
                </div>
                <div className="menuFooterContainer">
                    <div className="menuFooterContent">{t('menu-footer')}</div>
                </div>
            </div>
        </div>
    )
}

export default Menu;