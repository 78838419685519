import DetailsForm, { formType } from "./DetailsForm"

const EditUserForm = () => {

    return (
        <div className="registerFormContainer">
            <DetailsForm type={formType.Update} />
        </div>
    );
}

export default EditUserForm;