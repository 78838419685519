import { useState } from 'react';

function useInfoDialog() {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState("");  // To store the dynamic message

    const show = (dynamicMessage = "") => {
        setIsVisible(true);
        setMessage(dynamicMessage);  // Set the dynamic message
    };

    const hide = () => {
        setIsVisible(false);
        setMessage(""); // Reset the message
    };

    return {
        isVisible,
        show,
        hide,
        message  // Return the message so it can be used in the component
    };
}

export default useInfoDialog;
