import React, { useState, useRef, useEffect } from 'react';
import { useTranslation, Trans } from "react-i18next"
import Select from 'react-select';

function useUserInputDialog() {
    const [isVisible, setIsVisible] = useState(false);
    const [onConfirm, setOnConfirm] = useState(null);
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [inputValues, setInputValues] = useState([]);
    const [inputTypes, setInputTypes] = useState([]);
    const inputRefs = useRef([]);
    const [placeholders, setPlaceholders] = useState([]);
    const [headings, setHeadings] = useState([]);
    const [confirmBtnLabel, setConfirmBtnLabel] = useState([]);
    const [imageSrc, setImageSrc] = useState("");
    const [disabledInputs, setDisabledInputs] = useState([]);
    const [subTitle, setSubTitle] = useState("");
    const [masterClass, setMasterClass] = useState("");

    const show = (callback, dynamicTitle = "", dynamicMessage = "", inputTypesArray = ["text"], placeholdersArray = [], defaultValuesArray = [], inputHeadings = [], dynamicConfirmBtnLabel = "", dynamicImageSrc = "", inputDisabledArray = [], dynamicSubTitle = "", dynamicMasterClass = "") => {

        setIsVisible(true);
        setOnConfirm(() => callback);
        setMessage(dynamicMessage);
        setInputTypes(inputTypesArray);
        setInputValues(defaultValuesArray);
        setPlaceholders(placeholdersArray);
        setHeadings(inputHeadings);
        setTitle(dynamicTitle);
        setConfirmBtnLabel(dynamicConfirmBtnLabel);
        setImageSrc(dynamicImageSrc);
        setDisabledInputs(inputDisabledArray);
        setSubTitle(dynamicSubTitle);
        setMasterClass(dynamicMasterClass);
    };

    const setValue = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };

    const confirm = () => {
        if (onConfirm) {
            (async () => {
                const res = await onConfirm(inputValues, inputRefs.current);
                console.log(res);
                if (res !== true)
                    hide();
            })();

        }
    };

    const hide = () => {
        setIsVisible(false);
        setOnConfirm(null);
        setMessage("");
        setInputValues([]);
        setInputTypes([]);
        setHeadings([]);
        setPlaceholders([]);
        setTitle("");
        setConfirmBtnLabel("");
        setImageSrc("");
        setDisabledInputs([]);
        setSubTitle("");
        setMasterClass("");
    };

    return {
        isVisible,
        show,
        hide,
        confirm,
        message,
        inputValues,
        setInputValues,
        inputTypes,
        inputRefs,
        placeholders,
        headings,
        title,
        confirmBtnLabel,
        imageSrc,
        disabledInputs,
        subTitle,
        masterClass,
        setValue
    };
}

const UserInputDialog = ({ message, confirm, isVisible, hide, inputValues, setInputValues, inputTypes, inputRefs, placeholders, headings, title, confirmBtnLabel, imageSrc, disabledInputs, subTitle, masterClass }) => {
    const messageParts = message.split('\n');

    const handleConfirm = () => {
        confirm();
    };

    const { t } = useTranslation();

    useEffect(() => {
        if (isVisible && inputRefs.current[0])
            inputRefs.current[0].select();
    }, []);

    if (!isVisible) return null;


    return (
        <div className={`${masterClass} userDialogContainer`} >
            <div className="confirmDialog">
                {title && <h3 className='confirmDialogTitle'>{title}</h3>}
                {subTitle && <p className='confirmDialogSubtitle'>{subTitle}</p>}
                {imageSrc && <p className='dialogImage'><img className='userInputDialogImg' src={imageSrc} alt='' /></p>}
                <p className={messageParts.length > 1 ? "user-input-multiple-line-message" : ""}>{messageParts && messageParts.map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        {index !== messageParts.length - 1 && <br />}
                    </React.Fragment>
                ))}</p>

                {inputTypes.map((type, index) => (
                    <div className="input-default">
                        {headings[index] && <div className="div">{headings[index]}</div>}
                        {type === "dropdown" &&
                            <Select
                                options={inputValues[index]}
                                placeholder={placeholders[index]}
                                className='user-input-dropdown'
                                classNamePrefix='user-input-dropdown'
                                onChange={(e) => {
                                    const newInputValues = [...inputValues];
                                    newInputValues[index] = e.value;
                                    setInputValues(newInputValues);
                                }}
                                ref={el => inputRefs.current[index] = el}
                                isDisabled={!(disabledInputs) || disabledInputs[index]}
                            />
                        }
                        {type !== "dropdown" &&
                            <input
                                className={"input-style"}
                                key={index}
                                type={type}
                                value={inputValues[index]}
                                placeholder={placeholders[index]}
                                onChange={(e) => {
                                    const newInputValues = [...inputValues];
                                    newInputValues[index] = e.target.value;
                                    setInputValues(newInputValues);
                                }}
                                ref={el => inputRefs.current[index] = el}
                                disabled={!(disabledInputs) || disabledInputs[index]}
                            />
                        }
                    </div>
                ))}
                <button onClick={handleConfirm} className='userInputDialogConfirm'>{confirmBtnLabel || t('confirm-button')}</button>
                <button onClick={hide} className='userInputDialogCancel cancel-button'>{t('cancel-button')}</button>
            </div>
        </div>
    );
};

export { useUserInputDialog, UserInputDialog };
