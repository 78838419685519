import { useState } from 'react';

function useConfirmationDialog() {
    const [isVisible, setIsVisible] = useState(false);
    const [onConfirm, setOnConfirm] = useState(null);
    const [message, setMessage] = useState("");  // To store the dynamic message

    const show = (callback, dynamicMessage = "") => {
        setIsVisible(true);
        setOnConfirm(() => callback);
        setMessage(dynamicMessage);  // Set the dynamic message
    };

    const confirm = () => {
        if (onConfirm) {
            onConfirm();
            hide();
        }
    };

    const hide = () => {
        setIsVisible(false);
        setOnConfirm(null);
        setMessage(""); // Reset the message
    };

    return {
        isVisible,
        show,
        hide,
        confirm,
        message  // Return the message so it can be used in the component
    };
}

export default useConfirmationDialog;
