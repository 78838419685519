import { faEdit, faMapMarked, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Map from '../Map/Map';
import useMapDialog from '../Map/useMapDialog';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useLoading from "../Loading/useLoading";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../Loading/Loading";
import { useUserInputDialog, UserInputDialog } from '../UserAction/UserInputDialog';
import { useFloatingMessage, FloatingMessage } from '../UserAction/FloatingMessage';
import UserConfirm from "../UserAction/UserConfirm";
import useConfirmationDialog from "../UserAction/useConfirmationDialog";

const UserRoles = () => {
    const mapDialog = useMapDialog();
    const { t } = useTranslation();
    const loading = useLoading();
    const navigate = useNavigate();
    const [userRoles, setUserRoles] = useState([]);
    const [installation, setInstallation] = useState({});
    const addUserInput = useUserInputDialog();
    const changeUserRoleInput = useUserInputDialog();
    const floatMsg = useFloatingMessage();
    const userConfirmDialog = useConfirmationDialog();

    const showMessage = (message, isError) => {
        floatMsg.show(message, isError);
        // Set a timer to hide the message after 10 seconds
        const timer = setTimeout(() => {
            floatMsg.hide();
        }, 10000);

        // Clear the timer when the component unmounts or if the message changes
        return () => clearTimeout(timer);
    };

    const getData = async () => {
        const installationId = (await axios.get('/api/getCurrentInstallationId')).data;
        const installation = (await axios.post('/api/getInstallationFromId', { installationId: installationId })).data;
        const userRoles = (await axios.post('/api/getAllUserRolesForInstallation', { installationId: installationId })).data;

        setUserRoles(userRoles);
        setInstallation(installation);

        loading.hideLoading();
    };

    const isValidEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(email);
    };

    const addUser = async (userRole, email) => {
        if (isValidEmail(email)) {

            try {
                const user = (await (axios.get('/api/getCurrentUser'))).data;
                const response = await (axios.post('/api/addUserToInstallation', { installationId: installation.installationId, invitedUserEmail: email, inviterUser: user.userId, userRole: userRole.value }));

                if (response.status === 200)
                    return true;
                else
                    return false;

            } catch (error) {
                return false;
            }
        }
        else {
            return false;
        }
    };

    const addUserClicked = () => {
        const userRoleOptions = getUserRoleOptions();
        addUserInput.show(async (inputValues) => {
            loading.showLoading(t('loading-add-user'));
            if (!isValidEmail(inputValues[1])) {
                loading.hideLoading();
                showMessage(t('invalid-email-address'), true);
                return true;
            }

            const userRoles = (await (axios.get('/api/getAllUserRoles'))).data;
            const userRole = userRoles.find((role) => role.value === inputValues[0]);
            const response = await addUser(userRole, inputValues[1]);
            if (response) {
                console.log("user invited");
                showMessage(t('user-invited'), false);
                loading.hideLoading();

                return false;
            }
            else {
                console.log("user not invited");
                showMessage(t('user-invite-failed'), true);
                loading.hideLoading();

                return true;
            }


        }, t('add-user'), t('add-user-info'), ["dropdown", "email"], [t('choose-user-role'), t('email-placeholder')], [userRoleOptions, ""], ["", t('enter-email')], t('add-user-invitation'));
    };

    const getUserRoleOptions = async () => {
        const userRoles = (await (axios.get('/api/getAllUserRoles'))).data;

        return (userRoles.filter(userRole => userRole.value !== 0 && userRole.value !== 2).map(userRole => {
            return (
                {
                    value: userRole.value,
                    label: t(userRole.name)
                }
            );
        }));
    };

    const changeRoleClicked = async(element) => {
        const userRoleOptions = await getUserRoleOptions();
        addUserInput.show(async (inputValues) => {
            loading.showLoading(t('loading-updating-user'));
            console.log(inputValues[0]);
            const userRoles = (await (axios.get('/api/getAllUserRoles'))).data;
            let userRole = userRoles.find((role) => role.value === inputValues[0]);
            const response = await (axios.post('/api/updateUserRole', { installationId: installation.installationId, userId: element.user.userId, userRole: userRole.value }));

            if (response.status === 200) {
                await getData();
                showMessage(t('user-role-updated'), false);
                return false;
            }
            else {
                loading.hideLoading();
                showMessage(t('user-role-update-failed'), true);
                return true;
            }
        }, t('edit-user-role'), `${element.user.firstName} ${element.user.lastName}\n${element.user.company}\n\n${t(element.roleName)}`, ["dropdown"], [t('choose-user-role')], [userRoleOptions]);
    };

    const deleteUserClicked = async(element) => {
        userConfirmDialog.show(() => deleteUser(element), t('user-deletion-confirmation'));
    };

    const deleteUser = async(element) => {
        loading.showLoading(t('loading-deleting-user'))
        const response = (await axios.post('/api/deleteInstallationUser', {installationId: installation.installationId, userId: element.user.userId}));

        if(response.status === 200) {
            await getData();
            showMessage(t('user-deleted'), false);
            loading.hideLoading();
            userConfirmDialog.hide();
        } else {
            showMessage(t('user-deletion-error'), true);
            loading.hideLoading();
        }
    }

    useEffect(() => {
        loading.showLoading(t('loading-fetching-userroles'));
        getData();
    }, []);

    return (
        <div className="userRolesForm">
            <Map {...mapDialog} />
            <Loading isLoading={loading.isLoading} message={loading.message} />
            <UserInputDialog {...changeUserRoleInput} />
            <UserInputDialog {...addUserInput} />
            <UserConfirm {...userConfirmDialog}/>
            <FloatingMessage {...floatMsg} />
            <div className='installationIDContainer'>
                <div className='installation-info-editIcon'>
                    <FontAwesomeIcon icon={faEdit} size='lg' className="display-none" />
                </div>
                <div className='installation-info-container'>
                    <div className='installation-name'>ID.: {installation.installationId} - {installation.name}</div>
                    <div className='installation-address'>{installation.address}</div>
                </div>
                <div className='installation-info-mapIcon' onClick={() => mapDialog.show({ lat: installation.fullAddress.lat, lng: installation.fullAddress.lng }, true, installation.installationId)}>
                    <FontAwesomeIcon icon={faMapMarked} size='lg' />
                </div>
            </div>
            <div className="userRolesContent">
                <div className='userRolesListHeaderContainer'>
                    <p className='userRolesListHeader'>{t('user-roles-list-header')}</p>
                    <div className='userRolesListInfo'>{t('user-roles-list-info')}</div>
                </div>
                <div className='user-roles-table-header'>
                    <div className='user-roles-list-table-header-name'>{t('user-roles-list-name')}</div>
                    <div className='user-roles-list-table-header-role'>{t('user-roles-list-role')}</div>
                    <div className='user-roles-list-table-header-edit'>{t('user-roles-list-edit')}</div>
                </div>
                <div className='user-roles-list-table-container'>
                    <div className='user-roles-list' id="user-roles-table">
                        {userRoles && userRoles.map((userRole, index) => (
                            // Assuming each product has a unique id
                            <UserRoleElement key={userRole.user.userId} element={userRole} changeRoleClicked={changeRoleClicked} deleteUserClicked={deleteUserClicked} />
                        ))}
                    </div>
                </div>
                <div className="user-role-add">
                    <div className="user-role-add-label">{t('user-roles-add-user')}</div>
                    <button className="user-role-add-btn" onClick={addUserClicked}><FontAwesomeIcon icon={faPlus} /></button>
                </div>
            </div>
            <div className="btnGroup">
                <button className='back-button' onClick={() => navigate(`/installation/${installation.installationId}`)}>{t('back-button')}</button>
            </div>
        </div>
    );
}

const UserRoleElement = ({ element, changeRoleClicked, deleteUserClicked }) => {
    const { t } = useTranslation();

    return (
        <div className={"userRoleListRow"}>
            <div className="user-role-list-table-user">
                <div className='user-role-list-table-name'>{`${element.user.firstName} ${element.user.lastName}`}</div>
                <div className="user-role-list-table-company">{element.user.company}</div>
            </div>
            <div className='user-role-list-table-role'>{t(element.roleName)}</div>
            <div className='user-role-list-table-edit'>
                <button className="user-role-list-table-edit-btn" onClick={() => changeRoleClicked(element)}>
                    <FontAwesomeIcon icon={faEdit} />
                </button>
                <button className="user-role-list-table-delete-btn" onClick={() => deleteUserClicked(element)}>
                    <FontAwesomeIcon icon={faTrashCan} />
                </button>
            </div>
        </div>
    )
}

export default UserRoles;