import { useState, useRef, useEffect } from "react";
import logo from '../../LOBAS-LOGO.svg';
import defaultImage from '../../image.svg';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import useInfoDialog from "../UserAction/useInfoDialog";
import UserInfo from "../UserAction/UserInfo";
import { useFloatingMessage, FloatingMessage } from '../UserAction/FloatingMessage';
import f from './CredentialsFunctions';
import axios from 'axios';
import Loading from "../Loading/Loading";
import useLoading from '../Loading/useLoading';
import { useTokenNavigate, useUrlData } from '../NavigateHook';
import { useNavigate } from "react-router-dom";
import Select, { components } from 'react-select';
import norwegian from '../../Flag_of_Norway.svg';
import english from '../../Flag_of_the_United_Kingdom.svg';
import i18n from "i18next";

export const formType = {
    Create: "Create",
    Update: "Update"
}

const DetailsForm =
    ({
        type
    }) => {
        const firstNameRef = useRef();
        const lastNameref = useRef();
        const emailRef = useRef();
        const companyNameRef = useRef();
        const phoneNumberRef = useRef();
        const passwordRef = useRef();
        const confirmPasswordRef = useRef();
        const fileRef = useRef();
        const [previewUrl, setPreviewUrl] = useState("");
        const { t } = useTranslation();
        const infoDialog = useInfoDialog();
        const floatMsg = useFloatingMessage();
        const [missingInputs, setMissingInputs] = useState([]);
        const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
        const [waitingForValidation, setWaitingForValidation] = useState(false);
        const [redirectTo, setRedirectTo] = useState('/installations');
        const [urlData, setUrlData] = useState(null);
        const loading = useLoading();
        const dataFetcher = useUrlData();
        const nav = useTokenNavigate();
        const [user, setUser] = useState(null);
        const navigate = useNavigate();
        const [selectedOption, setSelectedOption] = useState(null);
        const [options, setOptions] = useState([]);

        const getUser = async () => {

            try {
                const response = await axios.get('/api/getCurrentUser');

                return response.data;
            } catch (error) {
                return null;
            }
        };

        const showMessage = (message, isError) => {
            floatMsg.show(message, isError);
            // Set a timer to hide the message after 10 seconds
            const timer = setTimeout(() => {
                floatMsg.hide();
            }, 10000);

            // Clear the timer when the component unmounts or if the message changes
            return () => clearTimeout(timer);
        };

        const infoBtnClick = () => {
            infoDialog.show(t('info-message-company-logo'));
        }

        const handleClick = event => {
            fileRef.current.click();
        }

        const handleSubmit = async (e) => {
            e.preventDefault();

            if (type === formType.Create) {
                await handleCreate();
            } else {
                await handleUpdate();
            }
        };

        const handleCreate = async () => {
            loading.showLoading(t('loading-register'));

            const refsToCheck = [
                { name: "firstName", ref: firstNameRef },
                { name: "lastName", ref: lastNameref },
                { name: "email", ref: emailRef },
                { name: "companyName", ref: companyNameRef },
                { name: "phoneNumber", ref: phoneNumberRef },
                { name: "password", ref: passwordRef },
                { name: "confirmPassword", ref: confirmPasswordRef },
            ];

            const missingFields = [];

            refsToCheck.forEach(({ name, ref }) => {
                if (!ref.current?.value) {
                    missingFields.push(name);
                }
            });

            if (missingFields.length > 0) {
                showMessage(t("missing-register-input-fields"), true);
                setMissingInputs(missingFields);
            } else if (!f.isValidPassword(passwordRef.current.value)) {
                showMessage(t("invalid-password"), true);
            } else if (!f.isValidEmail(emailRef.current.value)) {
                showMessage(t("invalid-email"), true);
            }
            else if (passwordRef.current.value !== confirmPasswordRef.current.value) {
                showMessage(t("passwords-dont-match"), true);
                setPasswordsDontMatch(true);
            } else {
                setPasswordsDontMatch(false);
                // All checks passed, send the registration request

                const userData = {
                    firstName: firstNameRef.current.value,
                    lastName: lastNameref.current.value,
                    email: emailRef.current.value,
                    companyName: companyNameRef.current.value,
                    phoneNumber: phoneNumberRef.current.value,
                    password: passwordRef.current.value,
                };

                let payload = new FormData();

                try {
                    for (const key in userData) {
                        payload.append(key, userData[key]);
                    }

                    const file = fileRef.current.files[0];

                    if (file) {
                        payload.append('file', file);
                    }

                    payload.append('redirectTo', redirectTo);

                    if (urlData.installationInvite) {
                        payload.append("installationInvite", urlData.installationInvite);
                    }

                    console.log("Sending registration request with data:", userData)
                    const response = await axios.post('/api/register', payload, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    console.log("Received response:", response);

                    // Handle response accordingly
                    if (response.status === 200) {
                        setWaitingForValidation(true);
                    } else if (response.status === 400) {
                        showMessage(t('user-exists'), true);
                    } else {
                        console.log("Error while registering:", response)
                        showMessage(t("registration-failed"), true);
                    }

                } catch (error) {
                    if (error.response.status === 400) {
                        showMessage(t('user-exists'), true);
                    } else {
                        console.log("Error while registering:", error);
                        showMessage(t("registration-failed"), true);
                    }
                }
            }

            loading.hideLoading();
        }

        const handleUpdate = async () => {
            loading.showLoading(t('loading-updating'));

            if (passwordRef.current.value && !f.isValidPassword(passwordRef.current.value)) {
                showMessage(t("invalid-password"), true);
            } else if (emailRef.current.value && !f.isValidEmail(emailRef.current.value)) {
                showMessage(t("invalid-email"), true);
            }
            else if (passwordRef.current.value !== confirmPasswordRef.current.value) {
                showMessage(t("passwords-dont-match"), true);
                setPasswordsDontMatch(true);
            } else {
                setPasswordsDontMatch(false);
                // All checks passed, send the registration request

                const userData = {
                    id: user.userId,
                    firstName: firstNameRef.current.value || user.firstName,
                    lastName: lastNameref.current.value || user.lastName,
                    email: emailRef.current.value || user.email,
                    companyName: companyNameRef.current.value || user.company,
                    phoneNumber: phoneNumberRef.current.value || user.telephoneNumber,
                    password: passwordRef.current.value
                };

                const payload = new FormData();

                try {
                    for (const key in userData) {
                        payload.append(key, userData[key]);
                    }

                    const file = fileRef.current.files[0];

                    if (file) {
                        payload.append('file', file);
                    }

                    for (let [key, value] of payload.entries()) {
                        console.log(key, value);
                    }

                    const response = await axios.post("/api/updateUser", payload);

                    if (response.status === 200) {
                        navigate("/profile");
                    } else {
                        console.log("Error while updating:", response)
                        showMessage(t("updating-user-failed"), true);
                    }
                } catch (error) {
                    console.log("Error while updating:", error);
                    showMessage(t("updating-user-failed"), true);
                }
            }

            loading.hideLoading();
        };

        const handleFileChange = (event) => {
            const file = event.target.files[0];
            if (file) {
                // setFile(file);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreviewUrl(reader.result);
                };
                reader.readAsDataURL(file);
            }
        };

        const setLanguageIcon = (language) => {
            switch (language) {
                case "NO":
                    return norwegian;
                case "EN":
                    return english;
                default:
                    return "";
            }
        }


        const CustomOption = props => (
            <components.Option {...props}>
                <img src={props.data.image} alt={props.data.label} style={{ width: 20, height: 20, marginRight: 10 }} />
                {props.data.label}
            </components.Option>
        );

        const CustomSingleValue = ({ children, ...props }) => (
            <components.SingleValue {...props}>
                <img src={props.data.image} alt={props.data.label} style={{ width: 20, height: 20, marginRight: 10 }} />
                {children}
            </components.SingleValue>
        );

        const handleDropdownChange = async (option) => {
            const optionValue = option.value;
            setSelectedOption(option);
            await i18n.changeLanguage(optionValue);
        }

        useEffect(() => {
            loading.showLoading();
            (async () => {
                const data = await dataFetcher.getUrlData();
                setUrlData(data)

                console.log("Register got the following redirect to url", data.redirectTo);
                if (data.redirectTo !== null)
                    setRedirectTo(data.redirectTo);

                const fetchedUser = await getUser();

                if (fetchedUser) {
                    if (type === formType.Create) {
                        nav.navigateTo(redirectTo);
                    } else {
                        if (fetchedUser.companyLogoUrl)
                            setPreviewUrl(fetchedUser.companyLogoUrl);

                        setUser(fetchedUser);
                    }
                }

                const options = [{ value: "no", label: "NO", image: setLanguageIcon("NO") }, { value: "en", label: "EN", image: setLanguageIcon("EN") }];
                setOptions(options);
                const currentLanguage = i18n.language;

                setSelectedOption(options.find(option => option.value === currentLanguage) ?? options[0]);
                loading.hideLoading();

            })();
        }, []);

        return (
            <form className="registerForm" onSubmit={handleSubmit}>
                <Loading isLoading={loading.isLoading} message={loading.message} />
                <FloatingMessage {...floatMsg} />
                <UserInfo {...infoDialog} />
                <div className="logoContainerLogin">
                    <img src={logo} alt="LOBAS" title="LOBAS" className="logosvg" />
                </div>
                {/* {!waitingForValidation && type === formType.Create && <Select
                    options={options}
                    components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                    value={selectedOption}
                    className={"user-registration-dropdown"}
                    classNamePrefix={"user-registration-dropdown"}
                    onChange={handleDropdownChange}
                />} */}
                {waitingForValidation ? <p className="checkEmailMessage">{t("check-email")}</p> : <div className="registerUserInputDiv">
                    <div className="input-default">
                        <label for="file-upload" class="custom-file-upload">
                            <div className="upload-icon-container">
                                <div className="upload-info-button-container">
                                    <button type="button" className="upload-info-button" onClick={infoBtnClick}><FontAwesomeIcon icon={faQuestionCircle} size="2xl" /></button>
                                </div>
                                <img class="upload-icon" src={previewUrl} alt="LOGO"></img>
                            </div>
                            <div className="upload-button-container">
                                <button type="button" className="upload-button" onClick={handleClick}>{t('upload-company-logo')}</button>
                            </div>
                        </label>
                        <input
                            className="company-logo"
                            accept="image/*"
                            type="file"
                            ref={fileRef}
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="input-group">
                        <div className="input-default">
                            <div className="div">{t('enter-first-name')}</div>
                            <input
                                className={"input-style" + (missingInputs.includes("firstName") ? " missingInput" : "")}
                                type="text"
                                placeholder="Ola"
                                ref={firstNameRef}
                                defaultValue={user && user.firstName}
                            />
                        </div>
                        <div className="input-default">
                            <div className="div">{t('enter-last-name')}</div>
                            <input
                                className={"input-style" + (missingInputs.includes("lastName") ? " missingInput" : "")}
                                type="text"
                                placeholder="Nordmann"
                                ref={lastNameref}
                                defaultValue={user && user.lastName}
                            />
                        </div>
                    </div>
                    <div className="input-default">
                        <div className="div">{t('enter-email')}</div>
                        <input
                            className={"input-style" + (missingInputs.includes("email") ? " missingInput" : "")}
                            type="email"
                            placeholder="olanordmann@gmail.com"
                            ref={emailRef}
                            defaultValue={user && user.email}
                            disabled={type === formType.Update}
                        />
                    </div>
                    <div className="input-default">
                        <div className="div">{t('enter-company-name')}</div>
                        <input
                            className={"input-style" + (missingInputs.includes("companyName") ? " missingInput" : "")}
                            type="text"
                            placeholder="Ola Nordmann AS"
                            ref={companyNameRef}
                            defaultValue={user && user.company}
                        />
                    </div>
                    <div className="input-default">
                        <div className="div">{t('enter-phone-number')}</div>
                        <input
                            className={"input-style" + (missingInputs.includes("phoneNumber") ? " missingInput" : "")}
                            type="number"
                            placeholder="000 00 000"
                            ref={phoneNumberRef}
                            defaultValue={user && user.telephoneNumber}
                        />
                    </div>
                    <div className="input-group">
                        <div className="input-default">
                            <div className="div">{t('enter-password')}</div>
                            <input
                                className={"input-style" + (passwordsDontMatch || missingInputs.includes("password") ? " missingInput" : "")}
                                type="password"
                                placeholder="*******"
                                ref={passwordRef}
                            />
                        </div>
                        <div className="input-default">
                            <div className="div">{t('confirm-password')}</div>
                            <input
                                className={"input-style" + (passwordsDontMatch || missingInputs.includes("confirmPassword") ? " missingInput" : "")}
                                type="password"
                                placeholder="*******"
                                ref={confirmPasswordRef}
                            />
                        </div>
                    </div>
                </div>}
                <div className="register-button">
                    {((type === formType.Create && !waitingForValidation) || type === formType.Update) && <button className="submit-button" type="submit" disabled={loading.isLoading}>{type === formType.Create ? t('register-button') : t('update-button')}</button>}
                    {type === formType.Update && <button type="button" onClick={() => navigate("/profile")} className="cancel-button editUserCancelBtn">{t('cancel-button')}</button>}
                </div>
            </form>
        );
    }

export default DetailsForm;