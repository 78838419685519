import axios from 'axios';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { useTranslation } from "react-i18next";


//Todo: Make it use the font color also. Now it defaults to white
const createControlSticker = async (date, approved) => {
    const colorList = (await axios.get('/api/getYearsAndColors')).data;

    const color = colorList[date.getFullYear().toString()].split(',');
    const canvas = document.createElement('canvas');
    const w = 500;  
    const h = 500;
    const or = 250;
    const ir = 150;

    canvas.width = w;
    canvas.height = h;

    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.arc(w / 2, h / 2, or, 0, 2 * Math.PI);
    ctx.fillStyle = color[0];
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(w / 2, h / 2, ir, 0, 2 * Math.PI);
    ctx.strokeStyle = color[1];
    ctx.lineWidth = 5;
    ctx.stroke();
    ctx.translate(w / 2, h / 2);
    ctx.font = "bold 70px Arial";

    for (let i = 0; i < 12; i++) {
        ctx.beginPath();
        ctx.moveTo(0, ir);
        ctx.lineTo(0, or);
        ctx.stroke();

        ctx.rotate(Math.PI / 12);

        let text = (i + 1).toString();

        let tDim = ctx.measureText(text);
        ctx.fillStyle = color[1];
        ctx.fillText(text, -tDim.width * 0.5, -(ir + (or - ir) * 0.5 - 25));
        console.log("This is the month: " + date.getMonth());
        if (i === date.getMonth()) {
            console.log("here is the month");
            ctx.fillStyle = "black";
            ctx.font = "bold 90px Arial";
            let xDim = ctx.measureText("X");
            ctx.fillText("X", -xDim.width * 0.5, -(ir + (or - ir) * 0.5 - 25))
            ctx.font = "bold 70px Arial";

        }

        ctx.rotate(Math.PI / 12);
    }



    ctx.translate(-w / 2, -h / 2);

    let text = date.getFullYear();
    ctx.font = "bold 100px Arial";
    let tDim = ctx.measureText(text);
    ctx.fillStyle = color[1];
    console.log(tDim);
    ctx.fillText(text, w / 2 - tDim.width * 0.5, h / 2 + 35);
    if (!approved) {

        const color = "rgba(255, 0, 0, 0.9)";

        // Draw outer red circle
        ctx.beginPath();
        const redCircleRadius = or - 10;
        ctx.arc(w / 2, h / 2, redCircleRadius, 0, 2 * Math.PI);
        ctx.lineWidth = 20;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.closePath();

        // Draw red X
        ctx.beginPath();
        ctx.lineWidth = 20;

        // Calculate endpoints based on circle radius
        const xMargin = Math.sqrt(2) * redCircleRadius / 2;
        ctx.moveTo(w / 2 - xMargin, h / 2 - xMargin);
        ctx.lineTo(w / 2 + xMargin, h / 2 + xMargin);
        ctx.moveTo(w / 2 + xMargin, h / 2 - xMargin);
        ctx.lineTo(w / 2 - xMargin, h / 2 + xMargin);
        ctx.stroke();

    }
    return canvas.toDataURL();
}

const cropImageToAspectRatio = async (imageUrl, aspectRatio) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            const originalWidth = image.width;
            const originalHeight = image.height;

            let newWidth, newHeight;

            if (originalWidth / originalHeight > aspectRatio) {
                // If the image is wider than the target ratio
                newHeight = originalHeight;
                newWidth = originalHeight * aspectRatio;
            } else {
                // If the image is taller than the target ratio
                newWidth = originalWidth;
                newHeight = originalWidth / aspectRatio;
            }

            // Calculate the top-left corner for cropping
            const startX = (originalWidth - newWidth) / 2;
            const startY = (originalHeight - newHeight) / 2;

            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(image, startX, startY, newWidth, newHeight, 0, 0, newWidth, newHeight);

            resolve(canvas.toDataURL('image/jpeg'));
        };
        image.onerror = reject;
    });
};

const fetchPdf = async (url) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
};

//TODO: Basically everything. This is a proof of concept, where it is shown that we can add images and text to a pdf. Hopefully we'll get a mockup of the report soon, so we can make it look nice.
const createPDF = async (listOfObjects) => {
    const templateURL = "https://files-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/binary/MD2GuE";
    const templatePdfBytes = await fetchPdf(templateURL);
    const templatePdfDoc = await PDFDocument.load(templatePdfBytes);

    const pdfDoc = await PDFDocument.create();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const topMargin = 100;
    let page;
    let currentY = topMargin;
    const bottomMargin = 100;

    const addPageNumbers = () => {
        const totalPages = pdfDoc.getPages().length;

        for (let i = 0; i < totalPages; i++) {
            page = pdfDoc.getPages()[i];
            drawText((i + 1).toString() + "/" + (totalPages), 10, page.getSize().height - 25);
        }
    }

    const addNewPageFromTemplate = async () => {
        const [templatePage] = await pdfDoc.copyPages(templatePdfDoc, [0]);
        page = pdfDoc.addPage(templatePage);
        currentY = topMargin; // Reset Y position for the new page
    };
    const drawText = (text, x, y) => {
        page.drawText(text, { x, y: page.getSize().height - y, size: 12, font });
    };
    const checkAndAddPage = async (itemHeight) => {
        if (currentY + itemHeight > page.getSize().height - bottomMargin) {
            await addNewPageFromTemplate();
        }
    };

    for (let j = 0; j < listOfObjects.length; j++) {
        await addNewPageFromTemplate(); // Start each item on a new template page

        const item = listOfObjects[j];


        await checkAndAddPage(20); // Height for title
        drawText(item.title, 10, currentY);
        currentY += 20;

        await checkAndAddPage(20); // Height for description
        drawText(item.description, 10, currentY);
        currentY += 20;

        // Manually handle table drawing and positioning
        // ...

        if (item.images) {
            const imgWidth = 250;
            for (let i = 0; i < item.images.length; i++) {
                const imageHeight = 250; // Height of the image
                await checkAndAddPage(imageHeight + 10); // Include some buffer

                const imageUrl = await cropImageToAspectRatio(item.images[i].url, 1);
                const image = await pdfDoc.embedJpg(imageUrl);
                page.drawImage(image, {
                    x: 10,
                    y: page.getSize().height - currentY - imageHeight,
                    width: imgWidth,
                    height: imageHeight
                });
                currentY += imageHeight + 10; // Include buffer after image

                await checkAndAddPage(20); // Height for caption
                let textWidth = font.widthOfTextAtSize(item.images[i].caption, 12);
                drawText(item.images[i].caption, imgWidth / 2 - 0.5 * textWidth, currentY);
                currentY += 20;
            }
        }
    }
    addPageNumbers();
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });

    return blob;
};

const functions = {
    createControlSticker,
    createPDF
}

export default functions;
