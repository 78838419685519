const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
    };

const isValidPassword = (password)=> {
    // Check password length
    if (password.length < 6) 
        return false;
    
    // Check for at least one letter
    const hasLetter = /[a-zA-Z]/.test(password);
    // Check for at least one number
    const hasNumber = /[0-9]/.test(password);

    return hasLetter && hasNumber;
}

const functions = {
    isValidEmail,
    isValidPassword
}

export default functions;
