import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import { useUserInputDialog, UserInputDialog } from '../UserAction/UserInputDialog';
import { useTranslation } from "react-i18next";
import f from '../Installation/InstallationFunctions';
import { useFloatingMessage, FloatingMessage } from '../UserAction/FloatingMessage';
import { Wizard } from '../Wizard/Wizard';
import FileRow from './FileRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faEdit, faExclamationCircle, faMapMarked, faTrashCan, faX } from '@fortawesome/free-solid-svg-icons';
import defaultImage from '../../image.svg';
import Map from '../Map/Map';
import useMapDialog from '../Map/useMapDialog';

//Todo: filter options on user roles
//Create view for inspections
//Move isValidEmail to a more appropriate place (the same code is used in multiple places)
const Installation = () => {
    const { id } = useParams();
    const [files, setFiles] = useState([]);
    const [installation, setInstallation] = useState([]);
    const [userRoles, setUserRoles] = useState(false);
    const [user, setUser] = useState([]);
    const [inspections, setInspections] = useState([]);
    const [installer, setInstaller] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState({});
    const [inspectionSignUrl, setInspectionSignUrl] = useState(defaultImage);
    const [showInspectionWizard, setShowInspectionWizard] = useState(false);
    const loading = useLoading();
    const navigate = useNavigate();
    const userFileInput = useUserInputDialog();
    const userEmailInput = useUserInputDialog();
    const userAddressAndNameInput = useUserInputDialog();
    const addUserInput = useUserInputDialog();
    const inspectionDialog = useUserInputDialog();
    const floatMsg = useFloatingMessage();
    const [isCompleted, setIsCompleted] = useState(false);
    const [roofType, setType] = useState("");
    const FSMImage = "https://images-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/imageFileData/MD2yH1?pubver=1";
    const FSGImage = "https://images-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/imageFileData/MD2yGY?pubver=1";
    const FSSImage = "https://images-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/imageFileData/MD2yH4?pubver=1";
    const FSFImage = "https://images-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/imageFileData/MD2GuK?pubver=1";
    const location = useLocation();

    const handleMapHide = () => {
        loading.showLoading(t('loading-get-data'));

        getInstallationData();
    };

    const mapDialog = useMapDialog(handleMapHide);

    const isValidEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(email);
    };

    const showMessage = (message, isError) => {
        floatMsg.show(message, isError);
        // Set a timer to hide the message after 10 seconds
        const timer = setTimeout(() => {
            floatMsg.hide();
        }, 10000);

        // Clear the timer when the component unmounts or if the message changes
        return () => clearTimeout(timer);
    };

    const toggleFileSelection = (fileId) => {
        setSelectedFiles(prev => ({
            ...prev,
            [fileId]: !prev[fileId]
        }));
    };

    const deleteFile = async (fileId) => {
        loading.showLoading(t('loading-delete-file'));
        try {
            const response = await axios.post('/api/deleteFile', { fileId: fileId });
            console.log(response);
            if (response.status === 200) {
                showMessage(t('delete-file-success'), false);
            }
            else
                showMessage(t('delete-file-error'), true);
        } catch (error) {

            showMessage(t('delete-file-error'), true);
        }

        loading.hideLoading();

    };

    const deleteFilesBtnClicked = () => {
        const filesToBeDeleted = Object.entries(selectedFiles).filter(([fileId, isSelected]) => isSelected).map(([fileId]) => fileId);

        const promises = filesToBeDeleted.map(fileId =>
            deleteFile(fileId)
        );

        (async () => {
            await Promise.all(promises);
            setSelectedFiles({});
            await getInstallationData();
        })();
    };

    const uploadFile = async (file, isEditable, type) => {
        const formData = new FormData();
        formData.append('file', file, encodeURIComponent(file.name)); // Include the file name
        formData.append('isEditable', true);
        formData.append('type', type)
        try {
            const response = await axios.post('/api/uploadFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data; // Return any data you need after the upload
        } catch (error) {
            throw new Error(t('file-upload-error'));
        }
    };

    const uploadFilesBtnClicked = () => {
        userFileInput.show(async (_, inputs) => {
            loading.showLoading(t('uploading-files-message'));
            await Promise.all(Array.from(inputs[0].files).map(async (file) => await uploadFile(file, true, 1)));
            getInstallationData();
        }, t('select-files-for-upload'), ["file"], [""]);

        setTimeout(() => {
            userFileInput.inputRefs.current[0].setAttribute('multiple', '');
        }, 100);
    }

    const downloadFile = async (fileUrl) => {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                showMessage(t('file-not-found'), true);
                return;
            }

            const blob = await response.blob();
            const downloadUrl = URL.createObjectURL(blob);
            const filename = fileUrl.split('/').pop(); // Extracts file name from URL

            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = filename || 'download'; // Provide a default filename if necessary
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(downloadUrl);
            a.remove();
        } catch (error) {
            console.error('Error downloading file', error);
        }
    };

    const downloadFilesBtnClicked = () => {
        const filesToBeDownloaded = Object.entries(selectedFiles).filter(([fileId, isSelected]) => isSelected).map(([fileId]) => Number(fileId));

        const promises = filesToBeDownloaded.map(fileId => {
            const file = files.find(file => file.fileId === fileId);
            const fileUrl = file.url;
            return downloadFile(fileUrl);
        });

        (async () => {
            await Promise.all(promises);
            setSelectedFiles({});
        })();
    }

    const sendFiles = async (files, email) => {

        const filepaths = files.map(file => file.url);
        const filenames = files.map(file => file.name);
        const installationId = installation.installationId;
        const installationName = installation.name;

        try {
            const response = await axios.post('/api/sendFilesByEmail', {
                filepaths,
                filenames,
                email,
                installationId,
                installationName
            });
            console.log(response);
            if (response.status === 200) {
                showMessage(t('send-files-success'), false);
            }
            else
                showMessage(t('send-files-error'), true);

            return response.data; // Return any data you need after the upload
        } catch (error) {
            throw new Error(t('send-files-error'));
        }
    }

    const sendFilesBtnClicked = () => {
        const fileIdsToBeSent = Object.entries(selectedFiles).filter(([fileId, isSelected]) => isSelected).map(([fileId]) => Number(fileId));
        const filesToBeSent = files.filter(file => fileIdsToBeSent.includes(file.fileId));

        userEmailInput.show(async (inputValues) => {
            loading.showLoading(t('sending-files-message'));
            if (!isValidEmail(inputValues[0])) {
                loading.hideLoading();
                showMessage(t('invalid-email-address'), true);
                return true;
            }

            await sendFiles(filesToBeSent, inputValues[0]);

            setSelectedFiles({});
            loading.hideLoading();

        }, t('send-selected-files'), filesToBeSent.map(file => file.name).join("\n"), ["email"], [t('email-placeholder')], [user.email], [t('enter-email')], "", "", [], t('selected-files'), "send-files");
    }

    const getInstallationData = async () => {
        const installation = (await axios.post('/api/getInstallationFromId', { installationId: id })).data;

        if (!installation) {
            navigate('/installations');
        }

        const inspections = (await axios.get('/api/inspectionsByInstallationId', { params: { installationId: id } })).data.sort((a, b) => new Date(b.inspectedAt) - new Date(a.inspectedAt));
        console.log(inspections);
        if (inspections.length > 0) {
            setInspectionSignUrl(await f.createControlSticker(new Date(inspections[0].inspectedAt), inspections[0].isApproved));
        }

        let foundType = {};
        const files = (await axios.get('/api/getFilesFromInstallationId', { params: { installationId: id } })).data.sort((a, b) => b.fileId - a.fileId).filter(file => {
            if (!foundType[file.type]) {
                foundType[file.type] = true;
                return true;
            }

            return false;
        });

        const completed = files.some(file => file.type === 7);
        setIsCompleted(completed);
        console.log(files);

        const userRoles = (await axios.post('/api/getUserRolesForInstallation', { installationId: installation.installationId })).data;
        //console.log("UserRole: " + userRoles[0].value);

        const installer = (await axios.post('/api/getInstaller', { installationId: installation.installationId })).data;

        const user = (await axios.get('/api/getCurrentUser')).data;

        const type = installation.installationId.substring(0, 3);

        setInspections(inspections);
        setUser(user);
        setInstaller(installer);
        setUserRoles(userRoles);
        setInstallation(installation);
        setFiles(files);
        setType(type);

        loading.hideLoading();
    };

    const { t } = useTranslation();

    useEffect(() => {
        loading.showLoading(t('loading-get-data'));

        getInstallationData();

    }, [id]);

    const addUser = async (userRole, email) => {
        if (isValidEmail(email)) {

            try {
                const user = (await (axios.get('/api/getCurrentUser'))).data;
                const response = await (axios.post('/api/addUserToInstallation', { installationId: id, invitedUserEmail: email, inviterUser: user.userId, userRole: userRole.value }));

                if (response.status === 200)
                    return true;
                else
                    return false;

            } catch (error) {
                return false;
            }
        }
        else {
            return false;
        }
    };

    const addUserClicked = async () => {
        const userRoleOptions = await getUserRoleOptions();
        addUserInput.show(async (inputValues) => {
            loading.showLoading(t('loading-add-user'));
            if (!isValidEmail(inputValues[1])) {
                loading.hideLoading();
                showMessage(t('invalid-email-address'), true);
                return true;
            }

            const userRoles = (await (axios.get('/api/getAllUserRoles'))).data;
            const userRole = userRoles.find((role) => role.value === inputValues[0]);
            const response = await addUser(userRole, inputValues[1]);
            if (response) {
                console.log("user invited");
                showMessage(t('user-invited'), false);
                loading.hideLoading();

                return false;
            }
            else {
                console.log("user not invited");
                showMessage(t('user-invite-failed'), true);
                loading.hideLoading();

                return true;
            }


        }, t('add-user'), t('add-user-info'), ["dropdown", "email"], [t('choose-user-role'), t('email-placeholder')], [userRoleOptions, ""], ["", t('enter-email')], t('add-user-invitation'));
    };

    const getUserRoleOptions = async () => {
        const userRoles = (await (axios.get('/api/getAllUserRoles'))).data;

        return userRoles.filter(userRole => userRole.value !== 0 && userRole.value !== 2).map(userRole => {
                return (
                    {
                        value: userRole.value,
                        label: t(userRole.name)
                    }
                );
        });
    };

    const getInstallationType = () => {
        const type = installation.installationId.substring(0, 3);

        switch (type) {
            case "FSM":
                return t('FSM');
            case "FSG":
                return t('FSG');
            case "FSS":
                return t('FSS');
            case "FSF":
                return t('FSF');
            default:
                return "";
        }
    }

    const inspectionComplete = async (data, res) => {
        console.log(data, "Result", res);

        loading.showLoading(t('submitting-inspection-report'));

        try {
            const date = new Date();
            const dateString = date.toLocaleDateString(undefined, { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '.');
            const timeString = date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' }).replace(/:/g, '.');
            const readableDate = dateString + ' ' + timeString;

            const inspectionData = {
                InspectionTemplateUrl: "https://files-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/binary/MD2DTC",
                InspectionUser: user,
                InstallationLogoUrl: getInstallationImage(),
                Installation: installation,
                InspectionData: data,
                InspectionDocumentName: `${t('control-document-name')} ${readableDate}.pdf`,
                InspectionType: 4,
                RoofType: getInstallationType(),
                RoofTypeLogoUrl: getInstallationImage(),
            }
            const pdfFile = await axios.post('/api/createInspectionDocument', { inspectionData: inspectionData }, {
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
            });

            try {
                const response = await axios.post('/api/createInspection', { isApproved: res, fileId: pdfFile.data.fileId }, {});

                if (response.status !== 200) {
                    loading.hideLoading();
                    showMessage(t('inspection-report-submit-error'), true);
                }


            } catch (error) {
                showMessage(t('inspection-report-submit-error'), true);
            }

            loading.hideLoading();
            showMessage(t('inspection-report-submitted'), false);
            getInstallationData();
        } catch (error) {
            console.log(error);
            loading.hideLoading();
        }

        setShowInspectionWizard(false);
    };

    const handleEdit = () => {
        userAddressAndNameInput.show(async (inputValues) => {
            loading.showLoading(t('updating-installation-message'));
            const response = await axios.post('/api/updateInstallation',
                {
                    installationId: installation.installationId,
                    name: inputValues[0],
                    address: inputValues[1],
                    fullAddress: installation.fullAddress,
                    createdDateTime: installation.createdDateTime,
                    modifiedDateTime: installation.modifiedDateTime,
                    comments: installation.comments
                });

            if (response.status === 200) {
                getInstallationData();
                showMessage(t('installation-update-successful'));
            } else {
                showMessage(t('installation-update-failed'), true);
            }
            loading.hideLoading();

        }, "", t('edit-installation'), ["text", "text"], [t('name-placeholder'), t('address-placeholder')], [installation.name, installation.address], [t("installation-name"), t("installation-address-edit")]);
    };

    const getInstallationImage = () => {
        let image = '';
        const type = installation.installationId.substring(0, 3);

        switch (type) {
            case "FSM":
                image = FSMImage;
                break;
            case "FSG":
                image = FSGImage;
                break;
            case "FSS":
                image = FSSImage;
                break;
            case "FSF":
                image = FSFImage;
                break;
            default:
                image = '';
        }

        return image;
    };

    const handleInspectionRegistration = () => {
        inspectionDialog.show(() => { setShowInspectionWizard(true) }, t('register-inspection'), "", ["text", "text", "text"], [], [user.company, `${user.firstName} ${user.lastName}`, new Date(Date.now()).toLocaleDateString()], [t('inspected-by-company'), t('inspected-by-user'), t('date')], t('next-button'), getInstallationImage(), [true, true, true], t(`inspection-${roofType}`));
    };

    return (
        <>
            <div className='installationContainer'>

                {showInspectionWizard && <Wizard onCompleted={inspectionComplete} onCancelled={() => setShowInspectionWizard(false)} flowFetchFunction={async () => await axios.get('/api/getInspectionFlow')} />}
                <Loading isLoading={loading.isLoading} message={loading.message} />
                <UserInputDialog {...userFileInput} />
                <UserInputDialog {...userEmailInput} />
                <UserInputDialog {...addUserInput} />
                <UserInputDialog {...userAddressAndNameInput} />
                <UserInputDialog {...inspectionDialog} />
                <FloatingMessage {...floatMsg} />
                <Map {...mapDialog} />
                <div className='installationIDContainer'>
                    <div className='installation-info-editIcon' onClick={() => handleEdit()}>
                        <FontAwesomeIcon icon={faEdit} size='lg' />
                    </div>
                    <div className='installation-info-container'>
                        <div className='installation-name'>ID.: {installation.installationId} - {installation.name}</div>
                        <div className='installation-address'>{installation.address}</div>
                    </div>
                    <div className='installation-info-mapIcon' onClick={() => mapDialog.show({ lat: installation.fullAddress.lat, lng: installation.fullAddress.lng }, true, installation.installationId)}>
                        <FontAwesomeIcon icon={faMapMarked} size='lg' />
                    </div>
                </div>
                <div className='installedByContainer'>
                    <div className='installedByLabel'>{t('installed-by')}</div>
                    <div className='installedBy'>
                        <div className='companyLogoContainer'>
                            <img alt='Installert av' className='installedByLogo' src={(installer && installer.companyLogoUrl) || defaultImage}></img>
                        </div>
                        <div className='installedByNameAndCompany'>
                            <div className='installedByName'>{installer.firstName} {installer.lastName}</div>
                            <div className='installedByCompany'>{installer.company}</div>
                            <div className='installedByPhone'>{installer.telephoneNumber}</div>
                        </div>
                    </div>
                </div>
                {inspections[0] && <div className='inspectionContainer'>
                    {/* <div className='installationControlBtnGroup'> */}
                    <div className='inspectionDateLabel'>{t('inspection-date-label')}</div>
                    <div className='inspectionDate'>{inspections[0] && new Date(inspections[0].inspectedAt).toLocaleDateString()}</div>
                    <div className='inspectionSignContainer'>
                        <img className="inspectionSign" alt='Kontrollmerke' src={inspectionSignUrl}></img>
                    </div>
                    <div className={`inspectionStatus ${inspections && inspections[0] && inspections[0].isApproved ? 'Approved' : 'NotApproved'}`}>
                        <FontAwesomeIcon icon={inspections[0] && inspections[0].isApproved ? faCheckDouble : faX} className='inspectionStatusIcon' />
                        {inspections[0] && inspections[0].isApproved ? t('inspection-approved') : t('inspection-not-approved')}
                    </div>
                    <div className='companyLogoContainer'>
                        <img alt='Kontrollert av' className='installedByLogo' src={(inspections[0] && inspections[0].user && inspections[0].user.companyLogoUrl) || defaultImage}></img>
                    </div>
                    <div className='inspectedByNameAndCompany'>
                        <div className='inspectedByName'>{inspections[0] && inspections[0].user.firstName} {inspections[0] && inspections[0].user.lastName}</div>
                        <div className='inspectedByCompany'>{inspections[0] && inspections[0].user.company}</div>
                        <div className='inspectedByPhone'>{inspections[0] && inspections[0].user.telephoneNumber}</div>
                    </div>
                    <div className='installationInspectionRegisterBtnContainer'>
                        {userRoles.length > 0 && isCompleted &&
                            <button className='installationControlRegisterBtn' onClick={() => { handleInspectionRegistration() }}>
                                {t('installation-control-register-btn')}
                            </button>}
                    </div>
                    <div className='installationInspectionsBtnContainer' onClick={() => navigate("/previousInspections", { state: { id: installation.installationId } })}>
                        {inspectionSignUrl && <button className='installationControlsBtn'>{t('installation-controls-btn')}</button>}
                        {/* </div> */}
                    </div>
                </div>}
                <div className={
                    inspections[0] && userRoles && userRoles.some(userRole => userRole.value < 3) ? "installationFilesContainer" :
                        inspections[0] && !(userRoles) ? "installationsFilesContainerM" :
                            !(inspections[0]) && userRoles && userRoles.some(userRole => userRole.value < 3) ? "installationsFilesContainerL" : "installationsFilesContainerXL"}>
                    {!files.find(file => file.type === 7) && <div className='installationNotCompleted'><FontAwesomeIcon icon={faExclamationCircle} size='lg' />{t('installation-not-completed')}</div>}
                    {files && files.map((f) => (
                        // Assuming each product has a unique id
                        <div className='fileRowContainer'>
                            <FileRow file={f} owner={true} key={f.fileId} />
                            {userRoles.length > 0 && <label className='checkbox-container'>
                                <input
                                    type='checkbox'
                                    checked={selectedFiles[f.fileId] || false}
                                    onChange={() => toggleFileSelection(f.fileId)}
                                    id={`checkbox-${f.fileId}`}
                                />
                                <span className='custom-checkbox'>
                                    {selectedFiles[f.fileId] && <FontAwesomeIcon icon={faCheck} size='2xl' />}
                                </span>
                            </label>}
                        </div>
                    ))}

                    {/* {((!files || files.length === 0)) && <div className="noFilesMessage">{t('upload-file-with-interaction')}</div>} */}
                    {/* {((!files || files.length === 0)) && <div className="noFilesMessage">{t('no-upload-message')}</div>} */}

                </div>
                {userRoles && userRoles.some(role => role.value < 3) && <div className='installationActionsContainer'>
                    {false && <button className='uploadFilesBtn' onClick={uploadFilesBtnClicked}>{t('upload-files-button')}</button>}
                    <button className='downloadFilesBtn' disabled={!Object.entries(selectedFiles).some(([fileId, isSelected]) => isSelected)} onClick={downloadFilesBtnClicked}>{t('download-files-button')}</button>
                    <button className='sendFilesBtn' disabled={!Object.entries(selectedFiles).some(([fileId, isSelected]) => isSelected)} onClick={sendFilesBtnClicked}>{t('send-files-button')}</button>
                    {false && <button className='deleteFilesBtn' onClick={deleteFilesBtnClicked}><FontAwesomeIcon className='iconLeft' icon={faTrashCan} />{t('delete-files-button')}</button>}
                    {/* <button className='createBOMBtn' onClick={() => navigate('/createBom')}>{t('create-bom-button')}</button> */}
                    <button className='addInspectorBtn' onClick={addUserClicked}>{t('add-user-button')}</button>
                    <button className='changeUserRolesBtn' onClick={() => navigate("/changeUserRoles")}>{t('change-user-roles')}</button>
                    {/* <button className='changeInstallerBtn' onClick={changeInstallerClicked}>{t('change-installer')}</button> */}
                    {/* {isCompleted && <button className='openProjectBtn' onClick={() => { }}>{t('open-project')}</button>} */}
                    {!isCompleted && <button className='installationFinishBtn' onClick={() => { navigate('/approveInstallation') }}>{t('complete-project')}</button>}
                    <button onClick={() => navigate("/installations")} className='installation-cancel-button'>{t('back-button')}</button>
                </div>}
            </div>
        </>
    );
};



export default Installation;
