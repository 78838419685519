import React, { useState, useEffect, useRef } from 'react';
import CameraComponent from '../Camera';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import { useUserInputDialog, UserInputDialog } from '../UserAction/UserInputDialog';
import { useTranslation } from "react-i18next";
import { useFloatingMessage, FloatingMessage } from '../UserAction/FloatingMessage';
import UserConfirm from '../UserAction/UserConfirm';
import useConfirmationDialog from '../UserAction/useConfirmationDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import UserInfo from '../UserAction/UserInfo';
import useInfoDialog from '../UserAction/useInfoDialog';

export const useWizardPages = (startPageData) => {
    const [finishedStatuses, setFinishedStatuses] = useState(Array(startPageData.length).fill(false));
    const [pageData, setPageData] = useState(startPageData);
    const setFinishedStatus = (pageIndex, status) => {
        const updatedStatuses = [...finishedStatuses];
        updatedStatuses[pageIndex] = status;
        setFinishedStatuses(updatedStatuses);
    };

    const updateDataset = (data) => {
        setFinishedStatuses(Array(data.length).fill(false));
        setPageData(data);
    };

    const updatePageData = (pageIndex, data) => {

        const updatedPageData = [...pageData];
        updatedPageData[pageIndex] = data;
        setPageData(updatedPageData);
    };


    return { finishedStatuses, setFinishedStatus, pageData, updatePageData, updateDataset };
};

export const WizardPageComponent = ({ updateData, data, id, finished, onCompletionChange }) => {
    const [showCamera, setShowCamera] = useState(false);
    const [images, setImages] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const imageNameInput = useUserInputDialog();
    const { t } = useTranslation();
    const floatingMsg = useFloatingMessage();
    const [selectedImgIndx, setSelectedImgIndx] = useState(-1);
    const confirmRemoveImage = useConfirmationDialog();
    const uploadImgRef = useRef(null);
    const [filesToProcess, setFilesToProcess] = useState([]);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [oldIndex, setOldIndex] = useState(-1);
    const stepInfo = useInfoDialog();

    console.log(selectedImgIndx);

    const showMessage = (message, isError) => {
        floatingMsg.show(message, isError);
        // Set a timer to hide the message after 10 seconds
        const timer = setTimeout(() => {
            floatingMsg.hide();
        }, 10000);

        // Clear the timer when the component unmounts or if the message changes
        return () => clearTimeout(timer);
    };

    const handleRadioChange = (event) => {
        const indx = parseInt(event.target.value)
        setSelectedIndex(indx);
        onCompletionChange(id, data.alternatives[indx].isConfirm || (data.alternatives[indx].imgRequired && images.length > 0) || (!data.alternatives[indx].imgRequired && (images.length > 0 || data.notes)));
        data.selectedAlternative = indx;
        updateData(id, data);
        if (!data.alternatives[indx].isConfirm && !data.alternatives[indx].imgRequired && !data.notes && images.length === 0) {
            showMessage(t('image-comment-required'), true);
        } else if (!data.alternatives[indx].isConfirm && data.alternatives[indx].imgRequired && images.length === 0) {
            showMessage(t('image-required'), true);
        }
    };

    const handleNotesChanged = (src) => {
        data.notes = src.target.value;
        updateData(id, data);

        if (selectedIndex >= 0) {
            handleRadioChange({ target: { value: selectedIndex } });
        }
    };

    const cameraButtonClicked = () => {
        setShowCamera(true);
    };

    const handleCameraFinished = (url) => {
        setShowCamera(false);
        imageNameInput.show(async (values) => {
            const name = values[0];
            if (name.length === 0) {
                console.log("showing floating message");
                floatingMsg.show(t('wizard-invalid-image-name'), true);
                return true;
            }

            images.push({ url, caption: values[0], bytes: url });
            setImages(images);
            data["images"] = images;
            updateData(id, data);

            if (selectedIndex >= 0) {
                handleRadioChange({ target: { value: selectedIndex } });
            }


        }, "", t('wizard-enter-image-name'), ['text'], [t('wizard-enter-image-name-placeholder')]);

        setTimeout(() => {
            imageNameInput.inputRefs.current[0].parentElement.insertBefore(img, imageNameInput.inputRefs.current[0].parentElement.firstChild);
        }, 100);
        let img = new Image();
        img.className = "camera-image-preview";
        img.src = url;
    };

    useEffect(() => {
        console.log("Updating the selected image index", images.length - 1);
        setSelectedImgIndx(images.length - 1);
    }, [images])

    useEffect(() => {
        console.log("Wizard selected image index changed", selectedImgIndx);
    }, [selectedImgIndx])

    const handleRemoveImage = () => {
        confirmRemoveImage.show(() => {
            images.splice(selectedImgIndx, 1);
            setImages([...images]);
            data["images"] = images;
            updateData(id, data);
        }, t('wizard-confirm-remove-image'));
    };

    const handleCameraError = (error) => {
        floatingMsg.show(t("camera-error"), true);
        setShowCamera(false);
    };

    const handleRenameImage = () => {
        imageNameInput.show(async (values) => {
            const name = values[0];
            if (name.length === 0) {
                console.log("showing floating message");
                floatingMsg.show(t('wizard-invalid-image-name'), true);
                return true;
            }

            images[selectedImgIndx].caption = name;

            console.log("setting images");
            setImages(images);

            data["images"] = images;
            updateData(id, data);
        }, "", t('wizard-enter-image-name'), ['text'], [t('wizard-enter-image-name-placeholder')]);
        setTimeout(() => {
            imageNameInput.setValue(0, images[selectedImgIndx].caption)
            imageNameInput.inputRefs.current[0].select();
        }, 100);
    };

    const handleUploadImage = () => {
        uploadImgRef.current.value = '';
        uploadImgRef.current.click();
        uploadImgRef.current.onchange = (e) => {
            if (e.target.files.length === 0) {
                console.log("No files found");
                return;
            }

            setFilesToProcess(Array.from(e.target.files));
            setCurrentFileIndex(0);
            /* if (e.target.files.length === 0) {

                console.log("No files found");
                return;

            }
            
            const file = e.target.files[0];
            const url = URL.createObjectURL(file);
            const reader = new FileReader();
            let imageString = "";

            reader.onload = async (e) => { 
                imageString = e.target.result;
            };

            reader.readAsDataURL(file);

            imageNameInput.show(async (values) => {
                if (values[0].length === 0) {
                    floatingMsg.show(t('wizard-invalid-image-name'), true);
                    return true;
                }

                images.push({ url, caption: values[0], bytes: imageString});
                console.log("setting images");
                setImages([...images]);
                data["images"] = images;
                updateData(id, data);

                if (selectedIndex >= 0) {
                    handleRadioChange({ target: { value: selectedIndex } });
                }

            }, "", t('wizard-enter-image-name'), ['text'], [t('wizard-enter-image-name-placeholder')]);

            setTimeout(() => {

                imageNameInput.setValue(0, e.target.files[0].name)
                imageNameInput.inputRefs.current[0].select();
                imageNameInput.inputRefs.current[0].parentElement.insertBefore(img, imageNameInput.inputRefs.current[0].parentElement.firstChild);

            }, 100);

            let img = new Image();
            img.className = "camera-image-preview";
            img.src = url; */

            /*if (e.target.files.length === 0) {
                console.log("No files found");
                return;
            }
            
            for (const file of e.target.files) {
                const url = URL.createObjectURL(file);
                const reader = new FileReader();
                let imageString = "";
            
                reader.onload = async (e) => { 
                    imageString = e.target.result;
                };
            
                reader.readAsDataURL(file);
            
                imageNameInput.show(async (values) => {
                    if (values[0].length === 0) {
                        floatingMsg.show(t('wizard-invalid-image-name'), true);
                        return true;
                    }
            
                    images.push({ url, caption: values[0], bytes: imageString});
                    console.log("setting images");
                    setImages([...images]);
                    data["images"] = images;
                    updateData(id, data);
            
                    if (selectedIndex >= 0) {
                        handleRadioChange({ target: { value: selectedIndex } });
                    }
                }, "", t('wizard-enter-image-name'), ['text'], [t('wizard-enter-image-name-placeholder')], [], [], "", url);
            
                setTimeout(() => {
                    imageNameInput.setValue(0, file.name)
                    imageNameInput.inputRefs.current[0].select();
                    imageNameInput.inputRefs.current[0].parentElement.insertBefore(img, imageNameInput.inputRefs.current[0].parentElement.firstChild);
                }, 100);
            
                let img = new Image();
                img.className = "camera-image-preview";
                img.src = url;*/
        }
    };

    const processText = (text) => {
        const textParts = text.split('\n');

        return (textParts && textParts.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index !== textParts.length - 1 && <br />}
            </React.Fragment>
        )))
    };

    const Choice = ({ alternative, index }) => {
        return (
            <label key={index} className={index === selectedIndex ? "wizard-input selected" : "wizard-input"}>
                <input
                    type="checkbox"
                    name={`status-${id}`}
                    value={index}
                    checked={index === selectedIndex}
                    onChange={handleRadioChange}
                    className='wizard-checkbox'
                />
                {alternative.text}
            </label>
        );
    };

    const handleStepInfo = () => {
        stepInfo.show(data.info);
    }

    useEffect(() => {

        const processFile = async (file) => {
            const url = URL.createObjectURL(file);
                const reader = new FileReader();
                let imageString = "";

                reader.onload = async (e) => {
                    imageString = e.target.result;
                };

                reader.readAsDataURL(file);

                imageNameInput.show(async (values) => {
                    if (values[0].length === 0) {
                        floatingMsg.show(t('wizard-invalid-image-name'), true);
                        return true;
                    }

                    images.push({ url, caption: values[0], bytes: imageString });
                    console.log("setting images");
                    setImages([...images]);
                    data["images"] = images;
                    updateData(id, data);

                    if (selectedIndex >= 0) {
                        handleRadioChange({ target: { value: selectedIndex } });
                    }

                    //setCurrentFileIndex(prevIndex => prevIndex + 1);

                }, "", t('wizard-enter-image-name'), ['text'], [t('wizard-enter-image-name-placeholder')], [""], [""], "", url);
        }

        if (filesToProcess.length > 0 && currentFileIndex < filesToProcess.length) {
            const currentFile = filesToProcess[currentFileIndex];

            if (currentFile /*&& (oldIndex !== currentFileIndex)*/) {
                processFile(currentFile).then(() => {
                    setCurrentFileIndex(prevIndex => prevIndex + 1);
                });
            }

            setOldIndex(currentFileIndex);
        }
    }, [filesToProcess, currentFileIndex]);

    return (

        <div className="wizardPage">
            <FloatingMessage {...floatingMsg} />
            <UserInputDialog {...imageNameInput} />
            <UserConfirm {...confirmRemoveImage} />
            <UserInfo {...stepInfo} />
            {showCamera && <CameraComponent onError={handleCameraError} onCapture={handleCameraFinished} onCaptureCancelled={() => { setShowCamera(false) }} />}
            <div className="wizardPageTitle">
                {data.alternatives.length === 1 && <Choice alternative={data.alternatives[0]} index={0} />}
                <h3>{data.title}</h3>
                {data.title &&
                    <div className='wizardInfoBtnContainer' onClick={() => handleStepInfo()}>
                        <FontAwesomeIcon icon={faInfoCircle} size='lg' />
                    </div>
                }
            </div>
            <div className="wizardPageDescription">
                <p>{processText(data.description)}</p>
            </div>
            {data.alternatives.length > 1 &&
                <div className="wizardButtonArea">
                    {data.alternatives.map((alternative, index) => (
                        <Choice alternative={alternative} index={index} />
                    ))}
                </div>
            }
            {data.alternatives.some(alternative => alternative.description) && <div className='wizardChoiceDescriptions'>
                <div className='wizardChoiceDescriptionsHeading'>{t('wizard-descriptions-heading')}</div>
                {data.alternatives.map(alternative => <div className='wizardChoiceDescription'>{`${alternative.text} - ${alternative.description}`}</div>)}
            </div>}
            {data.isCameraControlsVisible &&
                <>
                    <ImageCarousel images={images} selectedImgIndx={selectedImgIndx} setSelectedImgIndx={setSelectedImgIndx} ></ImageCarousel>
                    {selectedImgIndx >= 0 && <div className="wizardRenameImage wizardButtonContainer">
                        <button onClick={handleRenameImage}>{t('wizard-rename-image')}</button>
                    </div>}
                    {selectedImgIndx >= 0 && <div className="wizardRemoveImageButton wizardButtonContainer">
                        <button onClick={handleRemoveImage}>{t('wizard-remove-image')}</button>
                    </div>}
                    <div className="wizard-upload-image wizardButtonContainer">
                        <button onClick={handleUploadImage}>{t('wizard-upload-image')}</button>
                        <input type="file" accept="image/*" ref={uploadImgRef} style={{ display: 'none' }} multiple />
                    </div>
                    {/* <div className="wizardCameraButton wizardButtonContainer">
                        <button onClick={cameraButtonClicked}>{t('wizard-show-camera')}</button>
                    </div> */}
                </>
            }
            <div className="wizardTextArea">
                {data.isNotesControlVisible && <textarea onChange={handleNotesChanged} value={data.notes} placeholder={data.notesPlaceholder}></textarea>}
            </div>
        </div>
    );

};
