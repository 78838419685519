import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import UserConfirm from '../UserAction/UserConfirm';
import useConfirmationDialog from '../UserAction/useConfirmationDialog';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import { NavLink, useLocation } from 'react-router-dom';
import { useUserInputDialog, UserInputDialog } from '../UserAction/UserInputDialog';
import { useTranslation } from "react-i18next";
import QRScanner from '../QrReader';
import { useTokenNavigate } from '../NavigateHook';
import { useFloatingMessage, FloatingMessage } from '../UserAction/FloatingMessage';
import FSMImage from '../../Møne.svg';
import FSGImage from "../../Takbro.svg";
import FSSImage from "../../Stigrtrinn.svg";
import FSFImage from "../../Flate tak.svg";
import { useNavigate } from 'react-router-dom';
import f from '../Installation/InstallationFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMapMarked, faSearch } from '@fortawesome/free-solid-svg-icons';
import useMapDialog from '../Map/useMapDialog';
import Map from '../Map/Map';
import { InspectionSign } from '../Inspections/Inspections';

const Installations = () => {
    console.log("installations.jsx created");
    //const changeNameInput = useUserInputDialog();
    // const userInputDialog = useUserInputDialog();
    const confirmDialog = useConfirmationDialog();
    const loading = useLoading();
    const [installations, setInstallations] = useState([]);
    const [filteredInstallations, setFilteredInstallations] = useState([]);
    const [scanSticker, setScanSticker] = useState(false);
    const nav = useTokenNavigate();
    const floatingMessage = useFloatingMessage();
    const location = useLocation();
    const [installationImage, setInstallationImage] = useState('');
    const navigate = useNavigate();
    const mapDialog = useMapDialog();
    const [showSearch, setShowSearch] = useState(false);
    const searchString = useRef();

    const getInstallations = async () => {
        console.log("attempting to get installations");
        let installations = [];
        try {
            installations = (await axios.get('/api/getInstallationsFromUser')).data;
            console.log(installations);
        } catch (error) {
            nav.navigateTo('/login');
        }
        console.log(installations);
        const addressFilter = location.state && location.state.filters && location.state.filters.address;
        const searchFilter = location.state && location.state.filters && location.state.filters.searchString;

        if (installations && (addressFilter || searchFilter)) {
            const filteredInstallations = installations.filter(installation => (!(addressFilter) || installation.address === addressFilter) && ((!(searchFilter) || installation.installationId.includes(searchFilter)) || (!(searchFilter) || installation.address.includes(searchFilter))));
            installations = filteredInstallations;
        }
        console.log(installations);
        setInstallations(installations);
        setFilteredInstallations(installations);

        loading.hideLoading();
    }

    /* const promptDeleteInstallation = (installation) => {
        confirmDialog.show(() => deleteInstallation(installation.installationId), (t('prompt-delete-installation')) + installation.installationId + "?");
    } */

    useEffect(() => {
        loading.showLoading(t('fetching-message'));
        getInstallations();
    }, []);

    /* const deleteInstallation = async (installationId) => {
        loading.showLoading(t('delete-installation-message'));
        try {
            const response = await axios.post('/api/deleteInstallation', { installationId: installationId });
            console.log(response);
            getInstallations();

        } catch (error) {
            console.log(error);
        }
        loading.hideLoading();

    } */

    const addProjectManually = async () => {
        setScanSticker(true);
    }

    /* const changeName = async (installation) => {
        const name = installation.name;

        setTimeout(() => {
            changeNameInput.inputRef.current.setAttribute('value', name);
            changeNameInput.inputRef.current.select();
        }, 100);

        changeNameInput.show(async (inputValue) => {
            loading.showLoading(t('change-name-message'));
            try {
                console.log(inputValue);
                const newName = inputValue;
                const response = (await axios.post('/api/updateInstallation', { installationId: installation.installationId, userId: installation.userID, name: newName, createdDateTime: installation.createdDateTime, modifiedDateTime: installation.modifiedDateTime })).data;
                console.log("This is the file we got in return:", response);
                getInstallations();
            } catch (error) {
                console.log(error);
            }
            loading.hideLoading();
        }, (t('enter-new-name-form')), "text");
    }; */

    const handleScanCancelled = () => {
        setScanSticker(false);
    }

    const handleScan = (scanContent) => {
        setScanSticker(false);
        loading.showLoading(t("verifying-qr-code"));
        console.log("scanned content", scanContent);
        let cutoff = scanContent.indexOf('?');
        scanContent = scanContent.substring(cutoff + 1);
        const searchParams = Object.fromEntries(new URLSearchParams(scanContent));
        console.log("search params", searchParams);
        const sn = searchParams.id;
        const tkn = searchParams.tkn;

        console.log(sn, tkn);

        if (!(sn && tkn)) {
            //TODO: Show a userInform which explains that the QR code is invalid
            console.log("invalid parameters detected");
            return;
        }
        else {
            (async () => {
                console.log("Checking if installation exists", sn);
                const doesExist = (await axios.post('/api/getInstallationFromId', { installationId: sn })).data != null;
                console.log("doesExist", doesExist);
                if (doesExist) {
                    nav.navigateTo(`/installation/${sn}`);
                } else {
                    const user = (await axios.get('/api/getCurrentUser')).data;
                    console.log("Got user")
                    if (user) {
                        // await axios.post('/api/createInstallation', { installationId: id, userId: user.id, key: key });
                        nav.navigateTo(`/createInstallation`, { sn: sn, tkn: tkn });
                    }
                    else {
                        nav.navigateTo('/login', { redirectTo: "/?sn=" + sn + "&tkn=" + tkn });
                    }
                }

                loading.hideLoading();

            })();
        }

    }

    const handleScanError = (error) => {
        floatingMessage.show(t("camera-error"), error);
        setScanSticker(false);
    }

    const handleSearch = () => {
        if (installations) {
            const newFilteredInstallations = installations.filter(installations => installations.address.includes(searchString.current.value) ||
                installations.installationId.includes(searchString.current.value));

            setFilteredInstallations(newFilteredInstallations);
        }
    }

    const { t } = useTranslation();

    return (
        <div className='installationContainer'>
            {scanSticker && <QRScanner onScanSuccess={handleScan} onScanCancelled={handleScanCancelled} onError={handleScanError} />}
            <FloatingMessage {...floatingMessage} />
            <Loading {...loading} />
            <UserConfirm {...confirmDialog} />
            <Map {...mapDialog} />
            <div className='installationsContainer'>
                {(location.state && location.state.filters && location.state.filters.address) &&
                    <div className='installationIDContainer'>
                        <div className='installations-searchIcon' onClick={() => { setShowSearch((prevOpen) => !prevOpen) }}>
                            <FontAwesomeIcon className='' icon={faSearch} size='lg' />
                        </div>
                        <div className='installation-info-container'>
                            <div className='installation-address'>{filteredInstallations[0] && filteredInstallations[0].address}</div>
                        </div>
                        <div className='installation-info-mapIcon' onClick={() => mapDialog.show({ lat: filteredInstallations[0].fullAddress.lat, lng: filteredInstallations[0].fullAddress.lng }, false)}>
                            <FontAwesomeIcon icon={faMapMarked} size='lg' />
                        </div>
                    </div>
                }
                {showSearch &&
                    <div className='project-installation-search-container box-shadow'>
                        <div className="input-default">
                            <div className="div">{t('installation-search')}</div>
                            <input
                                className={"input-style"}
                                type="text"
                                placeholder="Skriv inn ID eller adresse"
                                ref={searchString}
                                defaultValue={location.state && location.state.filters && location.state.filters.searchString}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                }
                {filteredInstallations && filteredInstallations.map((installation) => (
                    <InstallationRow installation={installation} location={location} loading={loading} key={installation.installationId} />
                ))}
                {(!filteredInstallations || filteredInstallations.length === 0) && <div>{t('no-installation-message')}</div>}

            </div>
            <div className='installationsActionsContainer'>
                <button onClick={addProjectManually}>{t('add-installation')}</button>
                <button onClick={() => navigate("/projects")} className='cancel-button'>{t('back-button')}</button>
            </div>
        </div>
    );
};

const InstallationRow = ({ installation, location, loading }) => {
    const [inspections, setInspections] = useState([]);
    const { t } = useTranslation();

    const getData = async () => {
        const inspections = (await axios.get('/api/inspectionsByInstallationId', { params: { installationId: installation.installationId } })).data.sort((a, b) => new Date(b.inspectedAt) - new Date(a.inspectedAt));
        if (inspections && inspections.length > 0) {
            setInspections(inspections);
        }
    };

    const getInstallationImage = (installation) => {
        let image = '';
        const type = installation.installationId.substring(0, 3);

        switch (type) {
            case "FSM":
                image = FSMImage;
                break;
            case "FSG":
                image = FSGImage;
                break;
            case "FSS":
                image = FSSImage;
                break;
            case "FSF":
                image = FSFImage;
                break;
            default:
                image = '';
        }

        return image;
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className='installationRow'>
            <img className='installations-image' src={getInstallationImage(installation)} alt="LOGO / bilde" />
            <div className='installations-info-container'>
                <div className='installations-info'>
                    <div className="installationId">{t('serialnumber')} {installation.installationId} - {installation.name}</div>
                    {!(location.state && location.state.filters && location.state.filters.address) &&
                        <div className="installations-address">{installation.address}</div>
                    }
                </div>
                <div className='installations-bottom-row'>
                    <div className='installation-inspection-sign-container'>
                        <InspectionSign inspection={inspections && inspections[0]} loading={loading} key={inspections[0] && inspections[0].inspectionId}/>
                    </div>
                    <div className='installations-link-container'>
                        <NavLink to={`/installation/${installation.installationId}`} className='installationLink'>
                            {t('show-installation')}
                            {/* <button className='smallActionButton' onClick={(e) => {
                                e.preventDefault();
                                changeName(installation);
                            }}>{t('change-name-button')}</button> */}

                            {/* <button className='deleteButton' onClick={(e) => {
                                e.preventDefault();
                                promptDeleteInstallation(installation);
                            }}>{t('delete-installation-button')}</button> */}
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Installations
