import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TrackPageView = () => {
    const location = useLocation();

    // Function to send page view to GTM
    const trackPageView = (url) => {
        window.dataLayer.push({
            event: 'pageview',
            page: url
        });
    };

    // Track subsequent page views
    useEffect(() => {
        trackPageView(location.pathname + location.search);
    }, [location]);

    return null;
};

export default TrackPageView;