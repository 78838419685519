import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const isServer = typeof window === 'undefined';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'no',
    lng: 'no',
    ns: ['translation'],
    supportedLngs: ['no', 'en'],
    defaultNS: 'translation',
    ...(isServer && {
      backend: {
        loadPath: './public/locales/{{lng}}/translation.json',
      },
    })
  }, (err, t) => {
    if (err) return console.error('i18next initialization error:', err);
    console.log('i18next initialized');
  });

export default i18n;