import { useState, useRef, useEffect } from "react";
// import axios from 'axios';
// import Loading from "../Loading/Loading";
// import useLoading from '../Loading/useLoading';
import { useTranslation } from "react-i18next";
// import logo from '../../LOBAS-LOGO.svg';
// import f from './CredentialsFunctions'
import { useTokenNavigate, useUrlData } from '../NavigateHook';
// import defaultImage from '../../image.svg';
// import { useFloatingMessage, FloatingMessage } from '../UserAction/FloatingMessage';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
// import useInfoDialog from "../UserAction/useInfoDialog";
// import UserInfo from "../UserAction/UserInfo";
import DetailsForm, { formType } from "./DetailsForm";

const RegisterForm = () => {
    const nav = useTokenNavigate();
    // const dataFetcher = useUrlData();

    // const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
    // const [waitingForValidation, setWaitingForValidation] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(null);
    // const [missingInputs, setMissingInputs] = useState([]);
    // const [redirectTo, setRedirectTo] = useState('/installations');
    // const [urlData, setUrlData] = useState(null);
    // const floatMsg = useFloatingMessage();
    // const infoDialog = useInfoDialog();

    // const loading = useLoading();

    /* const showMessage = (message, isError) => {
        floatMsg.show(message, isError);
        // Set a timer to hide the message after 10 seconds
        const timer = setTimeout(() => {
            floatMsg.hide();
        }, 10000);

        // Clear the timer when the component unmounts or if the message changes
        return () => clearTimeout(timer);
    }; */

    /* const getUser = async () => {

        try {
            const response = await axios.get('/api/getCurrentUser');

            return response.data;
        } catch (error) {
            return null;
        }
    }; */

    useEffect(() => {
        /* loading.showLoading();
        (async () => {
            const data = await dataFetcher.getUrlData();
            setUrlData(data)

            console.log("Register got the following redirect to url", data.redirectTo);
            if (data.redirectTo !== null)
                setRedirectTo(data.redirectTo);

            const fetchedUser = await getUser();
            if (fetchedUser) {
                nav.navigateTo(redirectTo);
            }
            loading.hideLoading();

        })(); */
    }, []);

    /* const handleSubmit = async (refs) => {
        loading.showLoading(t('loading-register'));

        const refsToCheck = [
            { name: "firstName", ref: refs.firstNameRef },
            { name: "lastName", ref: refs.lastNameref },
            { name: "email", ref: refs.emailRef },
            { name: "companyName", ref: refs.companyNameRef },
            { name: "phoneNumber", ref: refs.phoneNumberRef },
            { name: "password", ref: refs.passwordRef },
            { name: "confirmPassword", ref: refs.confirmPasswordRef },
        ];

        const missingFields = [];

        refsToCheck.forEach(({ name, ref }) => {
            if (!ref.current?.value) {
                missingFields.push(name);
            }
        });

        if (missingFields.length > 0) {
            showMessage(t("missing-register-input-fields"), true);
            setMissingInputs(missingFields);
        } else if (!f.isValidPassword(refs.passwordRef.current.value)) {
            showMessage(t("invalid-password"), true);
        } else if (!f.isValidEmail(refs.emailRef.current.value)) {
            showMessage(t("invalid-email"), true);
        }
        else if (refs.passwordRef.current.value !== refs.confirmPasswordRef.current.value) {
            showMessage(t("passwords-dont-match"), true);
            setPasswordsDontMatch(true);
        } else {
            setPasswordsDontMatch(false);
            // All checks passed, send the registration request

            try {

                const userData = {
                    firstName: refs.firstNameRef.current.value,
                    lastName: refs.lastNameref.current.value,
                    email: refs.emailRef.current.value,
                    companyName: refs.companyNameRef.current.value,
                    phoneNumber: refs.phoneNumberRef.current.value,
                    password: refs.passwordRef.current.value,
                };

                let payload = new FormData();

                for (const key in userData) {
                    payload.append(key, userData[key]);
                }

                payload.append('redirectTo', redirectTo);

                const file = refs.fileRef.current.files[0];

                if (file) {
                    payload.append('file', file);
                }

                if (urlData.installationInvite) {
                    payload.append("installationInvite", urlData.installationInvite);
                }

                console.log("Sending registration request with data:", userData)
                const response = await axios.post('/api/register', payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log("Received response:", response);

                // Handle response accordingly
                if (response.status === 200) {
                    setWaitingForValidation(true);
                } else {
                    console.log("Error while registering:", response)
                    setErrorMessage(t("registration-failed"));
                }

            } catch (error) {
                console.log("Error while registering:", error);
                setErrorMessage(t("registration-failed"));
            }
        }

        loading.hideLoading();
    }; */

    const { t } = useTranslation();

    return (
        <div className="registerFormContainer">
            {/* <Loading isLoading={loading.isLoading} message={loading.message} />
            <FloatingMessage {...floatMsg} /> */}
            <DetailsForm
                type={formType.Create}
            />
            {/* <span className="navigateSpan registerLink "
                onClick={() => nav.navigateTo(`/Login`/* , { redirectTo: redirectTo } )}
            >
                {t('register-form-login-button')}
            </span> */}
        </div>
    )
}


export default RegisterForm
