import React from 'react';
import { useTranslation } from "react-i18next";

const UserConfirm = ({ message, confirm, isVisible, hide }) => {
    const handleConfirm = () => {
        confirm();
        hide();

    };

    const {t} = useTranslation();

    if (!isVisible) return null;

    return (
        <div className="userDialogContainer">
            <div className="confirmDialog">
                <p>{message}</p>
                <button onClick={handleConfirm}>{t('confirm-button')}</button>
                <button onClick={hide}>{t('cancel-button')}</button>
            </div>
        </div>
    );
};

export default UserConfirm;
