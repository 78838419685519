import React, { useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useTokenNavigate,useUrlData } from '../NavigateHook';

const InstallationIDDispatcher = () => {
    console.log("landed in dispatcher");
    const dataFetcher = useUrlData();
    const navigate = useTokenNavigate();

    const dispatch = async (id, key) => {
        console.log("Installation ID dispatcher received the following installation data",id, key);

        const doesExist = (await axios.post('/api/getInstallationFromId', { installationId: id })).data != null;
        console.log("Does installation exist?", doesExist);
        if (doesExist) {
            navigate.navigateTo(`/installation/${id}`);
        } else {
            const user = (await axios.get('/api/getCurrentUser')).data;
            console.log("dispatcher got the user", user);
            if (user) {
                // await axios.post('/api/createInstallation', { installationId: id, userId: user.id, key: key });
                navigate.navigateTo(`/createInstallation`,{ id: id, tkn: key});
            }
            else{
                console.log("navigating to unregistered installation page");
                navigate.navigateTo('/unregisteredInstallation', { redirectTo: "/?id="+id+"&tkn="+key});
            } //SF 20.11.23: Added new landing site for unregistered installations.
        }
    }

    useEffect(() => {
        (async()=>{
            const {id, tkn} = await dataFetcher.getUrlData();

            if(!(id && tkn))
                navigate.navigateTo("/login");
            else
                dispatch(id, tkn);
        })();

    }, []);

    return null; // Render nothing while deciding
}

export default InstallationIDDispatcher;
