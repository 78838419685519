import { faAngleDown, faAngleUp, faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FAQ = () => {
    const { t } = useTranslation();
    const [openCollapsible, setOpenCollapsible] = useState(null);

    const handleToggle = (id) => {
        if (openCollapsible === id) {
            setOpenCollapsible(null); // Close the current one if it's already open
        } else {
            setOpenCollapsible(id); // Open the clicked one
        }
    };

    return (
        <div className="guidefaqContainer">
            <div className="guideContainer">
                <div className="guideHeaing">{t('guide-heading')}</div>
                <p className="guideInfo">{t('portal-info')}</p>
                <div className="guideContent">
                    <Collapsible heading={t('how-to-use-portal')} text={t('how-to-use-portal-text')} isOpen={openCollapsible === 1} onToggle={() => handleToggle(1)} />
                    <Collapsible heading={t('how-to-installations')} text={t('how-to-installations-text')} isOpen={openCollapsible === 2} onToggle={() => handleToggle(2)} />
                    <Collapsible heading={t('how-to-project')} text={t('how-to-project-text')} isOpen={openCollapsible === 3} onToggle={() => handleToggle(3)} />
                    <Collapsible heading={t('how-to-registration')} text={t('how-to-registration-text')} isOpen={openCollapsible === 4} onToggle={() => handleToggle(4)} />
                    <Collapsible heading={t('how-to-anual-inspection')} text={t('how-to-anual-inspection-text')} isOpen={openCollapsible === 5} onToggle={() => handleToggle(5)} />
                    <Collapsible heading={t('how-to-user')} text={t('how-to-user-text')} isOpen={openCollapsible === 6} onToggle={() => handleToggle(6)} />
                </div>
            </div>
            <div className="faqContainer">
                <div className="faqHeaing">{t('faq-heading')}</div>
                <div className="faqContent">
                    <Collapsible heading={t('why-installation-name')} text={t('why-installation-name-text')} isOpen={openCollapsible === 7} onToggle={() => handleToggle(7)} />
                    <Collapsible heading={t('user-access-issue')} text={t('user-access-issue-text')} isOpen={openCollapsible === 8} onToggle={() => handleToggle(8)} />
                    <Collapsible heading={t('installation-registration-issue')} text={t('installation-registration-issue-text')} isOpen={openCollapsible === 9} onToggle={() => handleToggle(9)} />
                    <Collapsible heading={t('file-issue')} text={t('file-issue-text')} isOpen={openCollapsible === 10} onToggle={() => handleToggle(10)} />
                    <Collapsible heading={t('image-issue')} text={t('image-issue-text')} isOpen={openCollapsible === 11} onToggle={() => handleToggle(11)} />
                </div>
            </div>
        </div>
    )
}

const Collapsible = ({ heading, text, isOpen, onToggle }) => {
    const messageParts = text.split('\n');

    return (
        <div className="collapsibleContainer">
            <div className="collapsibleHeading" onClick={onToggle}>
                {heading}
                <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} size="xl" />
            </div>
            {isOpen && (
                <div className="collapsibleContent">
                    {messageParts && messageParts.map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            {index !== messageParts.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FAQ;