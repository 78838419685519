import { useState } from 'react';

function useMapDialog(onHide) {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({});  // To store the dynamic position
    const [showMarker, setShowMarker] = useState(false);
    const [id, setId] = useState('');

    const show = (dynamicPosition = {}, dynamicShowMarker = false, dynamicId = '') => {
        setIsVisible(true);
        setPosition(dynamicPosition);  // Set the dynamic message
        setShowMarker(dynamicShowMarker);
        setId(dynamicId);
    };

    const hide = (position) => {
        setIsVisible(false);
        setPosition({}); // Reset the message
        setShowMarker(false);
        setId('');

        if(onHide && position) {
            onHide();
        }
    };

    return {
        isVisible,
        show,
        hide,
        position,  // Return the message so it can be used in the component
        showMarker,
        id
    };
}

export default useMapDialog;