import React, { useState, useEffect } from 'react';

const ImageCarousel = ({ images, selectedImgIndx, setSelectedImgIndx }) => {
    const [fullScreen, setFullScreen] = useState(false);

    const selectImage = (indx) => {

        setSelectedImgIndx(indx);
    };

    const toggleFullScreen = () => {
        setFullScreen(!fullScreen);
    };
    console.log("selected indx in carousel", selectedImgIndx);

    if (!images.length) return null;

    return (
        <div className="imageCarouselContainer">
            <div className='imageCarouselList'>
                {images.map((image, index) => (
                    <div className='imageCarouselItem'>
                        <img
                            className={'imageCarouselImage'}
                            key={index}
                            src={image.url}
                            alt={`Imagenumber ${index + 1}`}
                            onClick={() => {selectImage(index); toggleFullScreen();}}
                            style={{ maxWidth: '100px', maxHeight: '100px', cursor: 'pointer' }}
                        />
                        <div className='imagePreviewCaption'>{image.caption}</div>
                    </div>
                ))}
            </div>
            {fullScreen && <div
                className={`imagePreview ${fullScreen ? 'fullScreen' : ''}`}
                onClick={toggleFullScreen}
            >
                {images[selectedImgIndx] && <img src={images[selectedImgIndx].url} alt='Selected' style={{ width: '100%' }} />}
            </div>}
        </div>
    );
}

export default ImageCarousel;
