import React, { useEffect, useRef, useState } from 'react';
import norwegian from '../../Flag_of_Norway.svg';
import english from '../../Flag_of_the_United_Kingdom.svg';

const LanguagePicker = ({ options, prompt, value, onChange }) => {
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState({});
  //const imageCache = useRef({});

  // const preloadImages = () => {
  //   options.forEach(option => {
  //     const image = new Image();
  //     image.src = setLanguageIcon(option);
  //     imageCache.current[option] = image;
  //   });
  // };

  const handleSelection = (option) => {
    onChange(option);
    setOpen(false);
  };

  const setLanguageIcon = (language) => {
    switch (language) {
      case "NO":
        return norwegian;
      case "EN":
        return english;
      default:
        return "";
    }
  }

  const handleClick = () => {
    if (options.length < 3) {
      const option = options.find(option => option !== value);
      if (option) {
        handleSelection(option);
      }
    } else {
      setOpen((prevOpen) => !prevOpen);
    }
  }

  useEffect(() => {
    //preloadImages();
  }, [options]);

  useEffect(() => {
    const languageIcon = setLanguageIcon(value);
    setLanguage({languageIcon: languageIcon, languageCode: value});
  }, [value]);

  return (
    <div className="dropdown">
      {!open && <div className="prompt" onClick={handleClick}>
        <img src={language.languageIcon} alt="" className='languageIcon'></img>
        {language.languageCode}
      </div>}

      {open && (
        <div className="options">
          {options.map((option, index) => (
            <div
              key={index}
              className="option"
              onClick={() => handleSelection(option)}
            >
              <img src={setLanguageIcon(option)} alt="" className='languageIcon'></img>
              {option}
            </div>
          ))}
        </div>
      )}
    </div >
  );
};

export default LanguagePicker;
