import React, { useLayoutEffect, useRef, useState } from 'react';
import { Html5Qrcode } from "html5-qrcode";
import Loading from './Loading/Loading';
import useLoading from './Loading/useLoading';
import { useTranslation } from "react-i18next";
const QrCodeScanner = ({ onScanSuccess, onScanCancelled, onError }) => {
    const scannerRef = useRef(null);
    const [html5QrCode, setHtml5QrCode] = useState(null);
    const [lastScanTime, setLastScanTime] = useState(0);
    const loading = useLoading();
    const debounceTime = 2000; // 2 seconds
    const { t } = useTranslation();

    const handleCancel = () => {
        html5QrCode.stop();

        if (onScanCancelled)
            onScanCancelled();
    };

    useLayoutEffect(() => {
        loading.showLoading(t("loading-scanner"))
        const rect = scannerRef.current.getBoundingClientRect();
        if (rect.width === 0 || rect.height === 0) {
            return;
        }

        const qrCodeInstance = new Html5Qrcode(scannerRef.current.id);
        setHtml5QrCode(qrCodeInstance);

        // const aspectRatio = window.innerWidth / window.innerHeight;

        const config = { fps: 10 };

        qrCodeInstance.start(
            { facingMode: "environment" },
            config,
            decodedText => {
                console.log("Decoded text running", decodedText);
                qrCodeInstance.stop();
                const currentTime = new Date().getTime();
                if (currentTime - lastScanTime > debounceTime) {
                    setLastScanTime(currentTime);
                    onScanSuccess(decodedText);
                }
            },
        ).then(() => loading.hideLoading()).catch(error => onError(error));

        return () => {
            if (html5QrCode) {
                html5QrCode.stop().catch(error => console.log(`Failed to stop scanning: ${error}`));
            }
        };
    }, [onScanSuccess]);

    return (<div className="scannerContainer">
        <Loading isLoading={loading.isLoading} message={loading.message} />
        <div id="scanner" ref={scannerRef}></div>
        <span className="scannerExplanation">{t("scan-qr-code-explanation")}</span>
        <button className="scannerCancelButton" onClick={handleCancel}>{t("cancel-qr-read")}</button>
    </div>);
};

export default QrCodeScanner;
