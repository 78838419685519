import { useState, useEffect } from 'react';

export function useFloatingMessage() {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [fading, setFading] = useState(false);
    const [fadeTimeout, setFadeTimeout] = useState(null);
    const show = (dynamicMessage = "", isError = false) => {

        setFading(false);
        setMessage(dynamicMessage);
        setIsError(isError);
        setIsVisible(true);
        setTimeout(()=>{
            window.addEventListener('click', removeFloatMessageFunc);
            window.addEventListener('keydown', removeFloatMessageFunc);
            window.addEventListener('mousemove', removeFloatMessageFunc);
        },1);

    };

    const removeFloatMessageFunc = (e) => {
        window.removeEventListener('click', removeFloatMessageFunc);
        window.removeEventListener('keydown', removeFloatMessageFunc);
        window.removeEventListener('mousemove', removeFloatMessageFunc);
  
        setTimeout(hide, 1000);
    }
    useEffect(() => {
        if (isVisible) {

        }
    }, [isVisible])

    const hide = (timeout = 1000) => {
        setFading(true);
        setTimeout(() => {
            if(fading){
                setIsVisible(false);
                setFading(false);
                setMessage("");
            }
        },timeout);

        console.log(timeout);
        setFadeTimeout(timeout);
    };

    return {
        isVisible,
        show,
        hide,
        message,
        isError,
        fading
    };
}

export const FloatingMessage = ({ message, isVisible, isError, fading }) => {
    if (!isVisible) return null;

    let className = "floatingMsg";
    if(fading) className += " fadeOut";
    if(isError) className += " error";
    return (
        <p className={className}>{message}</p>
    );
};
