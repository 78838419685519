import { useState, useEffect, useRef } from "react"
// import { useNavigate } from "react-router-dom"
// import { useLocation } from 'react-router-dom';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import logo from '../../LOBAS-LOGO.svg';
import f from '../Installation/InstallationFunctions';
import { useTokenNavigate, useUrlData } from '../NavigateHook';

const UnregisteredInstallation = () => {
    const { t } = useTranslation();
    const nav = useTokenNavigate();
    const [redirectTo, setRedirectTo] = useState('/login');
    const loading = useLoading();
    const dataFetcher = useUrlData();

    useEffect(() => {
        loading.showLoading();
        (async () => {
            const redirectURL = (await dataFetcher.getUrlData()).redirectTo;
            console.log("Login got the following redirect url", redirectURL);

            if (redirectURL)
                setRedirectTo(redirectURL);

            /*const fetchedUser = await getUser();
            if (fetchedUser) {
                nav.navigateTo(redirectTo);
            }*/
            loading.hideLoading();
        })();
    }, []);

    return (
        <div className="unregisteredInstallationFormContainer">
            <div className="unregisteredInstallationForm">
                <Loading isLoading={loading.isLoading} message={loading.message} />
                <div className="logoContainerUnregisteredInstallation">
                    <img src={logo} alt="LOBAS" title="LOBAS" className="logosvg" />
                </div>
                <div className="unregisteredInstallationInfoContainer">
                    {t('unregistered-installation-text')}
                </div>
                <div className="div">
                    <button className="submit-button" onClick={() => nav.navigateTo(`/login`, { redirectTo: redirectTo })}>{t('go-to-login')}</button>
                </div>
            </div>
        </div>
    )
};

export default UnregisteredInstallation;