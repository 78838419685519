import { useState, useEffect, useRef } from "react"
// import { useNavigate } from "react-router-dom"
// import { useLocation } from 'react-router-dom';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import logo from '../../LOBAS-LOGO.svg';
import f from '../Installation/InstallationFunctions';
import { useTokenNavigate, useUrlData } from '../NavigateHook';
import Select, { components } from 'react-select';
import norwegian from '../../Flag_of_Norway.svg';
import english from '../../Flag_of_the_United_Kingdom.svg';
import i18n from "i18next";

const LoginFormSimplified = () => {
    //Hooks

    const nav = useTokenNavigate();
    const dataFetcher = useUrlData();
    const usernameRef = useRef();
    const test = useRef();
    const passwordRef = useRef();
    const loading = useLoading();
    const { t } = useTranslation();
    const [redirectTo, setRedirectTo] = useState('/projects');
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);

    const [errorMessage, setErrorMessage] = useState(null);

    const getUser = async () => {
        try {
            const response = await axios.get('/api/getCurrentUser');

            return response.data;
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        loading.showLoading();
        (async () => {
            const redirectURL = (await dataFetcher.getUrlData()).redirectTo;
            console.log("Login got the following redirect url", redirectURL);

            if (redirectURL)
                setRedirectTo(redirectURL);

            const fetchedUser = await getUser();
            if (fetchedUser) {
                nav.navigateTo(redirectTo);
            }

            const options = [{ value: "no", label: "NO", image: setLanguageIcon("NO") }, { value: "en", label: "EN", image: setLanguageIcon("EN") }];
            setOptions(options);

            const currentLanguage = i18n.language;

            setSelectedOption(options.find(option => option.value === currentLanguage) ?? options[0]);

            loading.hideLoading();
        })();
    }, []);

    const userLoggedIn = (user) => {
        window.dispatchEvent(new CustomEvent('userLoggedIn', { detail: { user: user } }));
    }

    const isValidEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(email);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        loading.showLoading(t('loading-login'));

        const username = usernameRef.current.value;
        const password = passwordRef.current.value;

        if (!isValidEmail(username) || password.length < 1) {
            setErrorMessage(t('invalid-credentials'));
        } else {
            try {
                const response = await axios.post('/api/login', { username, password }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                console.log("This is the response we got from the server", response);
                if (response.status === 200) {
                    console.log("We are logged in. Redirecting to ", redirectTo);
                    const user = response.data;
                    nav.navigateTo(redirectTo)
                    userLoggedIn(user);
                }
            } catch (error) {
                console.error("Error:", error);
                setErrorMessage(t('invalid-credentials'));
            }
        }
        loading.hideLoading();
    };

    const setLanguageIcon = (language) => {
        switch (language) {
            case "NO":
                return norwegian;
            case "EN":
                return english;
            default:
                return "";
        }
    }


    const CustomOption = props => (
        <components.Option {...props}>
            <img src={props.data.image} alt={props.data.label} style={{ width: 20, height: 20, marginRight: 10, marginTop: 0, backgroundColor: "transparent" }} />
            {props.data.label}
        </components.Option>
    );

    const CustomSingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <img src={props.data.image} alt={props.data.label} style={{ width: 20, height: 20, marginRight: 10, marginTop: 0, backgroundColor: "transparent" }} />
            {children}
        </components.SingleValue>
    );

    const handleDropdownChange = async (option) => {
        const optionValue = option.value;
        setSelectedOption(option);
        await i18n.changeLanguage(optionValue);
    }


    return (
        <div className="loginFormContainer">
            <Loading isLoading={loading.isLoading} message={loading.message} />
            <form onSubmit={onSubmit} className="loginForm">
                <div className="logoContainerLogin">
                    <img src={logo} alt="LOBAS" title="LOBAS" className="logosvg" />
                </div>
                <div className="input-default">
                    <div className="div">{t('enter-credentials')}</div>
                    <input className="input-style"
                        type="email"
                        ref={usernameRef}
                        placeholder="olanormann@gmail.com"
                    />
                </div>
                <div className="input-default">
                    <div className="div">{t('password')}</div>
                    <input
                        className="input-style"
                        type="password"
                        ref={passwordRef}
                        placeholder="*********"
                    />
                </div>
                <div className="input-language">
                    <Select
                        options={options}
                        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                        value={selectedOption}
                        className={"user-login-dropdown"}
                        classNamePrefix={"user-login-dropdown"}
                        onChange={handleDropdownChange}
                    />
                </div>
                <div className="login-button">
                    <button className="submit-button" type="submit" disabled={loading.isLoading}>{t('login-button')}</button>
                </div>
                {errorMessage && <p>{errorMessage}</p>}
            </form>
            <div className="registerLinkLogin">
                <span className="navigateSpan" onClick={() => nav.navigateTo(`/ForgotPassword`, { redirectTo: redirectTo })}>{t('forgot-password-button')}</span>
                <span className="navigateSpan" onClick={() => nav.navigateTo(`/Register`, { redirectTo: redirectTo })}>{t('signup-button')}</span>
            </div>
        </div>
    )
}
export default LoginFormSimplified
