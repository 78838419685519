import { useState } from 'react';

function useLoading() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const showLoading = (msg) => {
    setIsLoading(true);
    setMessage(msg);
  };

  const hideLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
      setMessage('');
    }, 10);
  };

  return {
    isLoading,
    message,
    showLoading,
    hideLoading
  };
}

export default useLoading;
