import React, { useState, useEffect, useRef } from 'react';
import Loading from './Loading/Loading';
import useLoading from './Loading/useLoading';
import UserConfirm from './UserAction/UserConfirm';
import useConfirmationDialog from './UserAction/useConfirmationDialog';
import { useTranslation } from "react-i18next";
const CameraComponent = ({ onCapture, onCaptureCancelled, onError }) => {
    const videoRef = useRef(null);
    const [imageSrc, setImageSrc] = useState('');
    const loading = useLoading();
    const confirmCameraNotWorking = useConfirmationDialog();
    const { t } = useTranslation();
    const [stream, setStream] = useState(null);
    const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
    const [cameraDevices, setCameraDevices] = useState([]);

    async function getCameras() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setCameraDevices(videoDevices);
        // Optionally, start video with the first camera found
        if (videoDevices.length > 0) {
            startVideo(videoDevices[0].deviceId);
        }
    }

    const switchCamera = () => {
        if (cameraDevices.length > 1) {
            const nextCameraIndex = (currentCameraIndex + 1) % cameraDevices.length;
            setCurrentCameraIndex(nextCameraIndex);
            startVideo(cameraDevices[nextCameraIndex].deviceId);
        }
    };

    const startVideo = async (deviceId) => {
        try {
            const constraints = deviceId ? { video: { deviceId: { exact: deviceId } } } : { video: true };
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);

                setStream(mediaStream);
                // Set the video stream
                if (videoRef.current) {
                    videoRef.current.srcObject = mediaStream;
                    loading.hideLoading();
                }
            }
        } catch (error) {
            loading.hideLoading();
            onError(error);
        }
    };

    const stopVideo = () => {
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
          setStream(null);
        }
      };

    useEffect(() => {
        loading.showLoading()
        // Get access to the camera
        //startVideo();
        getCameras();
        
        return () => {
            // Cleanup, stop the stream
            stopVideo();
        };
    }, []);

    const captureImage = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
            const imageDataUrl = canvas.toDataURL('image/jpeg');
            setImageSrc(imageDataUrl);
            onCapture(imageDataUrl);

            videoElement.srcObject.getTracks().forEach(track => track.stop());
            videoElement.srcObject = null;
        }
    };

    const handleCancel = () => {
        // Call the passed onCaptureCancelled function
        if (onCaptureCancelled) {
            onCaptureCancelled();
        }
    };

    return (
        <div className="cameraContainer">
            <Loading {...loading}></Loading>
            <UserConfirm {...confirmCameraNotWorking} />
            <video ref={videoRef} autoPlay />
            <div className="cameraButtonsContainer">
                <button onClick={captureImage}>Capture</button>
                <button onClick={handleCancel}>Cancel</button>
                <button onClick={switchCamera}>Switch Camera</button> {/* Add switch button */}
                {imageSrc && <img src={imageSrc} alt="Captured" />}
            </div>
        </div>
    );
};

export default CameraComponent;
