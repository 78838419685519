import React from 'react';

const Footer = () => 
{
  return (
    <footer className='footer'>
      {/* Footer content goes here */}
    </footer>
  );
};

export default Footer;