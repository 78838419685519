// Other imports...
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

export const tokenize = async (jsonData) => {

    console.log("Tokenizing data", jsonData);
    try {
        const response = await axios.post('/api/tokenize', jsonData);
        console.log("Tokenized data", response);

        return response.data.token;
    } catch (error) {
        console.error("Error tokenizing data:", error);
        return null;
    }
};

export const detokenize = async (token) => {
    try {
        const response = await axios.post('/api/detokenize', { token });
        console.log("Result of detokenization", response);
        return response.data.payload;
    } catch (error) {
        console.error("Error detokenizing data:", error);
        return null;
    }
};

export const useTokenNavigate = () => {
    const navigate = useNavigate();

    const navigateTo = async (url, data) => {
        console.log("Use token navigate", data);

        console.log("URL", url);

        const token = data ? (await tokenize(data)) : null; // Assuming tokenize is defined elsewhere
        navigate(token ? `${url}?token=${token}` : url);

    };

    return { navigateTo };
};

export const useUrlData = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const getUrlData = async () => {
        const searchParams = new URLSearchParams(location.search);

        let data = {};
        for (let [key, value] of searchParams.entries()) {
            if (key === 'token') {
                const detokenizedData = await detokenize(value); // Assuming detokenize is defined elsewhere
                Object.assign(data, detokenizedData);
            } else {
                data[key] = value;
            }
        }
        // searchParams.delete('token');
        // navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

        console.log("Found the following data in the URL:", data);

        return data;
    };

    return { getUrlData };
};
