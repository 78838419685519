import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import logo from '../../LOBAS-LOGO.svg';
import f from './CredentialsFunctions'

const ForgotPassword = ({ redirectTo, abortable, redirectToAbort }) => {
    //Hooks
    const emailRef = useRef();
    const loading = useLoading();
    const location = useLocation();
    const { t } = useTranslation();
    const [requestPending, setRequestPending] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState(null);
    redirectTo = queryParams.get('redirectTo') ? queryParams.get('redirectTo') : redirectTo;
    abortable = abortable ? abortable : queryParams.get('abortable') === 'true';
    redirectToAbort = redirectToAbort ? redirectToAbort : queryParams.get('redirectToAbort');

    const userLoggedIn = (user) => {
        window.dispatchEvent(new CustomEvent('userLoggedIn', { detail: { user: user } }));
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        loading.showLoading(t('checking-email-reset-password'));

        const email = emailRef.current.value;


        if (!f.isValidEmail(email)) {
            setErrorMessage(t('invalid-email-reset-password'));
        } else {

            try {
                const response = await axios.post('/api/forgotPassword', { email });
                if (response.status === 200) {
                    setRequestPending(true);
                }
                else {
                    setErrorMessage(t('error-email-reset-password'));
                }

            } catch (error) {
                console.error("Error:", error);
                setErrorMessage(t('invalid-email-reset-password'));
            }
        }
        loading.hideLoading();
    };


    return (
        <div className="loginFormContainer">

            <Loading isLoading={loading.isLoading} message={loading.message} />
            <form onSubmit={onSubmit} className="loginForm">
                <div className="logoContainerLogin">
                    <img src={logo} alt="LOBAS" title="LOBAS" className="logosvg" />
                </div>
                {!requestPending && <div className="requestPasswordContainer">
                    <div className="input-default">
                        <div className="div">{t('enter-email')}</div>
                        <input className="input-style"
                            type="email"
                            placeholder="olanordmann@gmail.com"
                            ref={emailRef}
                        />
                    </div>
                    <div className="register-button">
                        <button className="submit-button" type="submit" disabled={loading.isLoading}>{t('request-password-change-link')}</button>
                    </div>
                    {errorMessage && <p>{errorMessage}</p>}
                </div>}
                {requestPending && <p className="checkEmailMessage">{t("check-email-password-change")}</p>}
            </form>
            <span className="registerLink navigateSpan" onClick={() => navigate(`/Login?redirectTo=${redirectTo}&abortable=${abortable}&redirectToAbort=${redirectToAbort}`)}>{t('login-button')}</span>
            {abortable && <span className="navigateSpan" onClick={() => navigate(redirectToAbort)}>{t('cancel-button')}</span>}
        </div>
    )
}
export default ForgotPassword
