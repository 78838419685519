import React from 'react';

const Loading = ({ isLoading, message }) => {
  console.log("Loading message is:",message);
  return (
    <div className={`loading-container ${isLoading ? 'visible' : ''}`}>
      <div className="loading-animation">
        {/* Your loading animation here */}
      </div>
      {message && <span>{message}</span>}
    </div>
  );
};

export default Loading;
