import React from 'react';
import { useTranslation } from "react-i18next";

const UserInfo = ({ message, isVisible, hide }) => {

    const { t } = useTranslation();

    if (!isVisible) return null;

    const messageParts = message.split('\n');

    return (
        <div className="userDialogContainer">
            <div className="confirmDialog">
                {messageParts && messageParts.map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        {index !== messageParts.length - 1 && <br />}
                    </React.Fragment>
                ))}
                <button onClick={hide}>{t('close-button')}</button>
            </div>
        </div>
    );
};

export default UserInfo;
