import { faEnvelope, faGlobe, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFloatingMessage, FloatingMessage } from '../UserAction/FloatingMessage';
import Loading from "../Loading/Loading";
import useLoading from '../Loading/useLoading';
import axios from "axios";

const Contact = () => {
    const { t } = useTranslation();
    const nameRef = useRef();
    const emailRef = useRef();
    const phoneNumberRef = useRef();
    const companyNameRef = useRef();
    const messageRef = useRef();
    const subjectRef = useRef();
    const [missingInputs, setMissingInputs] = useState([]);
    const floatMsg = useFloatingMessage();
    const loading = useLoading();

    const showMessage = (message, isError) => {
        floatMsg.show(message, isError);
        // Set a timer to hide the message after 10 seconds
        const timer = setTimeout(() => {
            floatMsg.hide();
        }, 10000);

        // Clear the timer when the component unmounts or if the message changes
        return () => clearTimeout(timer);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        loading.showLoading(t('loading-submitting-contact-schema'));

        const refsToCheck = [
            { name: "name", ref: nameRef },
            { name: "email", ref: emailRef },
            { name: "companyName", ref: companyNameRef },
            { name: "subject", ref: subjectRef },
            { name: "message", ref: messageRef }
        ];

        const missingFields = [];

        refsToCheck.forEach(({ name, ref }) => {
            if (!ref.current?.value) {
                missingFields.push(name);
            }
        });

        if (missingFields.length > 0) {
            showMessage(t("missing-register-input-fields"), true);
            setMissingInputs(missingFields);
        }

        const messageData = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            companyName: companyNameRef.current.value,
            phoneNumber: phoneNumberRef.current.value,
            subject: subjectRef.current.value,
            message: messageRef.current.value,
        };

        const response = (await axios.post('/api/sendContactSchema', { messageData }));

        if (response.status === 200) {
            floatMsg.show(t('contact-schema-sent'));
        } else {
            floatMsg.show(t('contact-schema-failed'));
        }

        loading.hideLoading();
    };

    return (
        <div className="contactContainer">
            <Loading isLoading={loading.isLoading} message={loading.message} />
            <FloatingMessage {...floatMsg} />
            <div className="contactHeading">{t('contact-us')}</div>
            <div className="vendorInfo">
                <div className="vendorName">Lonevåg Beslagfabrikk AS</div>
                <div className="vendorContactPointsContainer">
                    <a className="vendorContactPoint" href="mailto:lobas@lobas.no">
                        <div className="vendorContactPointLogoContainer"><FontAwesomeIcon icon={faEnvelope} size="xl" /></div>
                        <div className="vendorContactPointHeading">{t('send-email')}</div>
                        <div className="vendorContactPointValue">lobas@lobas.no</div>
                    </a>
                    <a className="vendorContactPoint" href="tel:+4756193100">
                        <div className="vendorContactPointLogoContainer"><FontAwesomeIcon icon={faPhone} size="xl" /></div>
                        <div className="vendorContactPointHeading">{t('call-us')}</div>
                        <div className="vendorContactPointValue">56 19 31 00</div>
                    </a>
                    <a className="vendorContactPoint" href="https://lobas.no" target="_blank" rel="noopener noreferrer">
                        <div className="vendorContactPointLogoContainer"><FontAwesomeIcon icon={faGlobe} size="xl" /></div>
                        <div className="vendorContactPointHeading">{t('visit-website')}</div>
                        <div className="vendorContactPointValue">lobas.no</div>
                    </a>
                    <a className="vendorContactPoint" href="https://maps.app.goo.gl/GR1SCbbyLgUycYuM6" target="_blank" rel="noopener noreferrer">
                        <div className="vendorContactPointLogoContainer"><FontAwesomeIcon icon={faLocationDot} size="xl" /></div>
                        <div className="vendorContactPointHeading">{t('visit-us')}</div>
                        <div className="vendorContactPointValue">Hovdavegen 18 5282 Lonevåg</div>
                    </a>
                </div>
                <div className="contactSchema">
                    <div className="contactSchemaHeading">{t('contact-schema-heading')}</div>
                    <div className="contactSchemaSubheading">{t('contact-schema-subheading')}</div>
                    <form className="contactSchemaInputFields" onSubmit={handleSubmit}>
                        <div className="input-default">
                            <div className="div">{t('enter-name')}</div>
                            <input
                                className={"input-style" + (missingInputs.includes("name") ? " missingInput" : "")}
                                type="text"
                                placeholder="Ola Nordmann"
                                ref={nameRef}
                            />
                        </div>
                        <div className="input-default">
                            <div className="div">{t('enter-email')}</div>
                            <input
                                className={"input-style" + (missingInputs.includes("email") ? " missingInput" : "")}
                                type="email"
                                placeholder="olanordmann@gmail.com"
                                ref={emailRef}
                            />
                        </div>
                        <div className="input-default">
                            <div className="div">{t('enter-phone-number')}</div>
                            <input
                                className={"input-style" + (missingInputs.includes("phoneNumber") ? " missingInput" : "")}
                                type="number"
                                placeholder="000 00 000"
                                ref={phoneNumberRef}
                            />
                        </div>
                        <div className="input-default">
                            <div className="div">{t('enter-company-name')}</div>
                            <input
                                className={"input-style" + (missingInputs.includes("companyName") ? " missingInput" : "")}
                                type="text"
                                placeholder="Ola Nordmann AS"
                                ref={companyNameRef}
                            />
                        </div>
                        <div className="input-default">
                            <div className="div">{t('enter-subject')}</div>
                            <input
                                className={"input-style" + (missingInputs.includes("subject") ? " missingInput" : "")}
                                type="text"
                                placeholder={t('enter-subject-here')}
                                ref={subjectRef}
                            />
                        </div>
                        <div className="input-default">
                            <div className="div">{t('enter-message')}</div>
                            <textarea
                                className={'input-style' + (missingInputs.includes("message") ? " missingInput" : "")}
                                type="text"
                                placeholder={t('enter-message-here')}
                                ref={messageRef}
                                onFocus={e => e.target.select()}

                            />
                        </div>
                        <button className="contactSchemaSubmitBtn" type="submit">{t('send-message')}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact;