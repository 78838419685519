import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { WizardPageComponent, useWizardPages } from './WizardPageComponent';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faArrowLeft, faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const Wizard = ({ onCompleted, onCancelled, flowFetchFunction }) => {
    const loading = useLoading();
    const [currentStep, setCurrentStep] = useState(0);
    const { t } = useTranslation();
    const [heading, setHeading] = useState('');
    const { finishedStatuses, setFinishedStatus, pageData, updatePageData, updateDataset } = useWizardPages([
        {
            "title": "",
            "description": "",
            "alternatives": [],
            "notes": ""
        }
    ]
    );

    useEffect(() => {
        (async () => {
            loading.showLoading(t(''));
            const response = await flowFetchFunction();
            updateDataset(response.data.steps);
            setCurrentStep(0);
            setHeading(response.data.name);
            loading.hideLoading();
        })();
    }, []);

    const handleCompletionChange = (pageIndex, isComplete) => {
        setFinishedStatus(pageIndex, isComplete);
    };

    const goToNextStep = () => {
        if (currentStep < finishedStatuses.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            console.log(pageData);
            onCompleted(pageData);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleComplete = () => {
        onCompleted(pageData, pageData.every((page) => page.alternatives.length === 0 || page.alternatives[page.selectedAlternative].isConfirm));
    }

    const cancelWizard = () => {
        onCancelled();
    };

    return (
        <div className="wizardBackground">
            <Loading isLoading={loading.isLoading} message={loading.message} />
            <div className="wizardContainer">
                <div className='wizardHeaderContainer'>
                    <div className='wizardHeader'>{heading}</div>
                    <div className='wizardPageNumber'>{currentStep + 1}/{finishedStatuses.length}</div>
                </div>
                {finishedStatuses.map((finished, index) => (
                    <div
                        key={index}
                        className="wizardContentContainer"
                        style={{ display: currentStep === index ? 'block' : 'none' }}
                    >
                        <WizardPageComponent
                            id={index}
                            finished={finished}
                            onCompletionChange={handleCompletionChange}
                            data={pageData[index]}
                            updateData={updatePageData}
                        />
                    </div>
                ))}
                <div className="wizardButtonsContainer">
                    {currentStep === 0 && <button onClick={cancelWizard} className='cancel-button'>{t("wizard-cancel-button")}</button>}
                    {currentStep > 0 && <button className="wizardButtonPrevious" onClick={goToPreviousStep}><FontAwesomeIcon icon={faAngleLeft} className='iconLeft' />{t("wizard-previous-button")}</button>}
                    {currentStep < finishedStatuses.length - 1 && (
                        <button
                            onClick={goToNextStep}
                            disabled={!(finishedStatuses[currentStep] || pageData[currentStep].alternatives.length === 0)}
                        >
                            {t("wizard-next-button")}
                            <FontAwesomeIcon icon={faAngleRight} className='iconRight' /></button>
                    )}
                    {currentStep === finishedStatuses.length - 1 && (
                        <button onClick={handleComplete} disabled={!finishedStatuses[currentStep]}>{t("wizard-finished-button")}</button>
                    )}
                </div>
            </div>
        </div>
    );
};
